import React from "react"
import { NavLink } from "react-router-dom"
import "./NavbarTopMenu.scss"

function NavbarTopMenu({ isActive, closeMenu }) {
    if (!isActive) return null
    return (
        <div className="NavbarTopMenu">
            <div className="container  pt-0 pt-md-5">
                <div className="w-100">

                    <div onClick={() => closeMenu()} className="heading pt-4 pt-md-5 d-flex spce-between">
                        <span className="title">Mehr dashdesign;</span>
                        <i className="fa-solid fa-times"></i>
                    </div>
                    <div className="column">
                        {moreItems.map((i, k) => (
                            i.url ?
                                <a
                                    href={i.url}
                                    target="_blank"
                                    className={({ isActive }) => isActive ? "d-block isActive" : "d-block"}
                                >
                                    <span>{i.title}</span>
                                </a>
                                :
                                <NavLink
                                    key={k}
                                    to={i.uri}
                                    onClick={() => closeMenu()}
                                    className={({ isActive }) => isActive ? "d-block isActive" : "d-block"}
                                >
                                    <span>{i.title}</span>
                                </NavLink>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NavbarTopMenu


const moreItems = [
    {
        title: "Über dashdesign;",
        uri: "/about/",
    },
    {
        title: "Referenzen",
        uri: "/referenzen/",
    },
    {
        title: "Partner",
        uri: "/partner/",
    },
    {
        title: "Jobs",
        uri: "/karriere/",
    },
    {
        title: "Kontakt",
        uri: "/kontakt/",
    },

    {
        title: "Serverstatus",
        uri: "/server-status",
    },

    {
        title: "Kundenbereich",
        url: "https://backend.dashdesign.eu/",
    },
];
  // + Team, + Support??