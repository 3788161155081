import React from "react";
import Helmet from "react-helmet";
import ListRender from "../../components/api/strapi/ListRender/ListRender";
import SubBox from "../../components/defaults/boxes/SubBox";
import BigKeyValueList from "../../components/defaults/text/BigKeyValue/BigKeyValueList";
import { ProjectListItem } from "../../components/more/projects/ProjectListItem/ProjectListItem";
import TextPage from "../../components/pages/TextPage";
import { PROJECT_LIST_ENDPOINT } from "../../const/api/endpoints";



function referenzPage() {

    return (
        <div>
            <Helmet>
                <title>Öffentliche Referenzen | dashdesign; </title>
                <meta name="keywords" content="Grünberg, StartUp, Investment, Projekte, Kunden, Überblick, Refernezen, Gießen, Mittelhessen" />
                <meta name="description" content="Über 60 Kunden" />
            </Helmet>

            <TextPage title="Öffentliche Referenzen">

                <div className="mb-5 pb-5">
                    <p>Als junges Unternehmen von Digital Natives sind wir immer wieder überrascht, wie schlecht Deutschland digitalisiert ist, gerade im europäischen Vergleich. Unsere Mission ist es dies zu ändern und mit jeder App und jeder Webseite, die wir veröffentlichen, leisten wir unseren Beitrag. Und genau diesen Beitrag wollen wir Ihnen hier unter unseren Referenzen vorstellen.</p>
                    <SubBox>
                        <BigKeyValueList
                            className="d-flex"
                            listClassName={"row"}
                            itemClassName={"col-12 col-md"}
                            list={stats} />
                    </SubBox>
                </div>

                <div className="mb-5">
                    <h3><span>Auflistung unserer Projekte</span></h3>
                    <p>Hier sehen Sie nur einen Teil unserer tollen Partner und Kunden. In den von uns entwickelten Stadt-Apps geben wir vielen weiteren Unternehmen die Möglichkeit sich noch einfacher zu digitalisieren.
                    </p>
                </div>
                {/* <div className="mb-5">
                    <button>Apps</button>
                    <button>Websites</button>
                </div> */}

                <ListRender
                    className="row"
                    endpoint={PROJECT_LIST_ENDPOINT}
                    render={data => <ProjectListItem {...data} />}
                />

            </TextPage>
        </div >
    )

}
export default referenzPage

const stats = [


    {
        value: "22",
        after: "<",
        text: "Projekte",
        small: "...die wir umgesetzt haben und pflegen.",
    },
    {
        value: "120",
        after: "<",
        text: "Zufriedene Kunden",
        small: "...und über 2.000 aktive Nutzer;innen.",
    },
    {
        pre: "",
        value: "4",
        after: "<",
        text: "Entwickler;innen",
        small: "...arbeiten an unseren Projekten.",
    },

    // {
    //     pre: "",
    //     value: "2.000 +",
    //     text: "Aktive Nutzer",
    //     small: "arbeiten an unseren Projekten",
    // },

]