import React from 'react'

import "./PageLoader.css"

function PageLoader() {
    return (
        <div className="PageLoader">
            <div className="content">
                <div className="loading">
                    <p>Lädt</p>
                    <span />
                </div>
            </div>
        </div>
    )
}

export default PageLoader
