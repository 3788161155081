import React from "react"
import { PopupButton } from "react-calendly"
import { Link } from "react-router-dom"
import TextPage from "../../components/pages/TextPage"
import "./TerminPage.scss"

function TerminPage({ }) {


    let imageUrl = process.env.PUBLIC_URL + "/media/image/company/online-beratungstermin-bei-dashdeign.jpg"

    return (
        <TextPage img={imageUrl} id="TerminPage" className="TerminPage" title="Termin vereinbaren">

            <p>Vereinbaren Sie gerne einen kostenlosen Beratungstermin. Wir nehmen uns gerne Zeit und freuen uns auf Sie!</p>



            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-4 p-2">

                    <PopupButton
                        url="https://calendly.com/dashdesign-eu/austausch-1h"
                        rootElement={document.getElementById("root")}
                        text={<>Termin Online buchen<br /><small>(Via. Calendly.com)</small></>}
                        className="w-100 m-0"
                    />
                </div>
                <Link className="col-12 col-md-6 col-lg-4 p-2" to="/kontakt">
                    <button className="w-100">
                        Alternativ<br />
                        <small>Kontaktieren Sie uns!</small>
                    </button>
                </Link>
            </div>

        </TextPage>
    )
}
export default TerminPage