import React, { useState } from "react";
import FeatureBox from "../../../components/defaults/boxes/FeatureBox/FeatureBox";
import "./WelcomeBox.scss";

function WelcomeBox({ slogan, services }) {
  const [AppName, setAppName] = useState("Die Stadt App");
  return (
    <div className="WelcomeBox">
      <div className="container ">

        <div className="pb-5">
          <h2>Unsere Stärken</h2>
          <span className="subTitle">Die folgenden Dienstleitungen bieten wir an:</span>
        </div>



        <div className="appBoxes row">
          {services.map((i, k) =>
            <div className="col-12 col-md-4 col-lg-4 p-0" key={k}>
              <FeatureBox
                {...i.attributes}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WelcomeBox;
