import React from "react"
import { useParams } from "react-router-dom"
import ItemRenderer from "../../../components/api/strapi/ItemRender/ItemRender"
import StrapiFileList from "../../../components/api/strapi/StrapiFileList/StrapiFileList"
import StrapiImage from "../../../components/api/strapi/StrapiImage/StrapiImage"
import TextPage from "../../../components/pages/TextPage"
import "./ProjectSinglePage.scss"

function ProjectSinglePage({ }) {

    let { slug } = useParams()

    let endpoint = "/projects?filters[slug][$eq]=" + slug + "&populate=*"

    return (
        <ItemRenderer
            endpoint={endpoint}
            render={(d) => <Render {...d} />}
        />
    )
}
export default ProjectSinglePage

function Render(d) {

    console.log(d)

    return <TextPage title={d.projectTitle} className="ProjectSinglePage">
        <div className="d-flex flex-column">

            <StrapiImage image={d.logo} />

            <p><b>{d.shortDescription}</b></p>

            <div>
                <h2>Infos zum Projekt:</h2>
                <p>{d.description || "Keine Beschreibung verfügbar."}</p>
            </div>

            {d.pdfs && d.pdfs?.data &&
                <div>
                    <h3>Dokumente:</h3>
                    <div>
                        <StrapiFileList data={d.pdfs?.data || []} />
                    </div>
                </div>
            }


            {d.contactEmail &&
                <div>
                    <h3>Kontaktdaten:</h3>
                    <a className="mail" href={`mailto:${d.contactEmail}`}>
                        <i className="fa-solid fa-envelope" />
                        {d.contactEmail}
                    </a>
                </div>
            }


        </div>
    </TextPage>
}