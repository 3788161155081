import React from 'react'
import { Helmet } from 'react-helmet'
import AppIcon from '../../components/defaults/boxes/AppIcon'
function JobsPage() {
    return (
        <div className="JobsPage">
            <Helmet>
                <title>Stellenangebote & Praktika | dashdesign.eu </title>
                <meta name="description" content="Arbeite von überall aus – kleines Team & großes Ziel – wir freuen uns auf dich!" />
            </Helmet>


            <div className="container">
                <h1>Stellenangebote & Praktika </h1>
                <br />
                <p>Du hast Lust, von egal wo, in einem kleinen Start-up zu arbeiten oder ein Praktikum zu machen? Nice, wir freuen uns auf deine Bewerbung! <span>🙂</span></p>
                <div className="boxi mt-5 row align-items-center">
                    <AppIcon appName="Grünberg" />
                    <p className="m-0 ml-5">In der Grünberg/Mücke-App finden Sie tolle Stellenangebote: <a href="https://gruenberg.app/jobs" target="_blank">gruenberg.app/jobs</a></p>
                </div>
            </div>
        </div>
    )
}
export default JobsPage