import React from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"

function GoBackButton({ children }) {
    let navigate = useNavigate()
    // let loc = useLocation()
    // console.log(loc) Alternative um herauszufindne, ob der User von Google oder einer anderen webstie kommt

    //Wenn von Google: nav zur Startseite
    //Wenn con anderer Seite: Zurück
    return (
        <Link to="/" title="Zurück" className="GoBackButton" onClick={() => { navigate(-1) }}>
            {children}
        </Link>
    )
}
export default GoBackButton