import React from "react";
import { Link } from "react-router-dom";
import AppleChartsImg from "../../../assets/image/apps/AppleCharts.png";
import Quote from "../../../components/defaults/text/Quote";
import TextPage from "../../../components/pages/TextPage";
import LandingPage from "../../home/boxes/LandingPage";
import "./AppsPage.scss";

function AppsPage() {
  document.title = "Wir realisieren Ihre App Idee | Präzise und Schnell – dashdesign;";
  return (
    <TextPage
      className="AppsPage"
      metaTitle="App-Entwicklung für iOS, Android & Desktops gleichzeitig 🤫 Kostenfreies Angebot"
      keywords="Apps, Server, Idee, Anwendung, Grünberg, Plattformunabhängig, Konrad Meyer, Design, Schnell, iOS, Android, Insulaner Unner sück, Corona-App, Grünberg-App, StartUp, Marketing, StartUp, Idee"
      metaDescription={"Smartphone, Desktop,  Server - mit unserer breit gefächerten Erfahrung im Bereich App-Entwicklung sind wir der optimale Partner um Ihre Idee zu realisieren. 📍Grünberg"}
      noContainer

      title="App-Entwicklung"
      subTitle="Innovativ und userfreundlich – Unsere Apps überzeugen."
      img="https://blog.dashdesign.eu/wp-content/uploads/2023/01/smartmockups_kmuhfcad-scaled.jpg"
    >

      <div className="container mt-5 pt-5">
        <h2>Wieso dashdesign?</h2>
        <p>Sie suchen einen zuverlässigen Partner für Ihre App-Entwicklung?
          Wir bieten Ihnen innovative Lösungen, die auf Ihre individuellen Bedürfnisse abgestimmt sind.

          Unsere Expertise reicht von der Konzeption über die Entwicklung bis hin zur Veröffentlichung Ihrer App.
        </p>

        {/* <p>
          Eines unserer wichtigsten Anliegen ist die Plattformunabhängigkeit. Das bedeutet, dass Ihre App auf Android, iOS und Desktop gleichzeitig funktioniert und Sie so eine maximale Reichweite erzielen. Darüber hinaus ist die Entwicklung bei uns kostengünstiger, da wir nur eine App entwickeln müssen, die auf allen Plattformen gleichermaßen gut funktioniert.
        </p>

        <p>
          Wir legen großen Wert auf den umfassenden Support, den wir Ihnen während und nach der Entwicklung bieten. Dank unserer Erfahrung von über 10 Jahren im Bereich der App-Entwicklung können wir Ihnen sichere und zukunftssichere Lösungen anbieten. Unsere Software ist kompatibel mit Ihren bestehenden Systemen und wir entwickeln sie ganz nach Ihren Bedürfnissen und Wünschen.
        </p>

        <p>
          Zusätzlich bieten wir Ihnen die Möglichkeit, Ihre Inhalte einfach und bequem per CMS anzupassen. Außerdem sind wir unabhängig von Drittanbietern und können Ihnen daher flexible und individuelle Lösungen anbieten. Unser Design ist stilvoll, zeitlos und kann sich in jeder Branche durchsetzen. Kreative Ideen und maßgeschneiderte Software gehören ebenfalls zu unseren Stärken.
        </p> */}

        <p>
          Entscheiden Sie sich für Dashdesign als Ihren Partner in der App-Entwicklung und profitieren Sie von unserer umfassenden Expertise und unserem Engagement für Ihren Erfolg.
        </p>
      </div>

      <div className="mb-5">
        <LandingPage {...data} />
      </div>

      {/* <div className="container">
        <h2>Referenzen</h2>
        <h3>Kommunale Apps</h3>
      </div> */}

      <hr />



      <div className="boxReferenzen">
        <div className="container mt-5">

          <h2 className="mb-5 pb-5 pt-5">Wie läuft ein App-Projekt normalerweiße ab?</h2>
          <p>Ihre Idee verdient eine intelligente Umsetzung.</p>



          <AreaItem title="1. IDEE">
            <h3>Wir lieben Fortschritt!</h3>
            <p>dashdesign;'s Unternehmensmission ist es die Welt durch innovative und durchdachte Software voranzubringen.
              Ob es sich um eine kleine Webanwendung oder eine komplizierte Smartphone-App handelt –
              wir finden die Lösung um Ihre Idee zu realisieren.</p>
          </AreaItem>

          <AreaItem title="2. DESIGN">
            <div className="mb-5">
              <Quote interruption author="Steve Jobs" text="Design ist nicht nur, wie etwas aussieht. Design ist auch, wie etwas funktioniert" />
            </div>
            <h3>Mehr als der erste Eindruck </h3>
            <p>Das Design ist nicht nur für den ersten Eindruck relevant.
              Das Design entscheidet auch, ob Ihre Mitarbeiter die neue Anwendung wirklich benutzen
              und Zeit sparen.</p>
            <h3>Das Gesamtpaket</h3>
            <p>Eine App sollte vom ersten Kundenkontakt an ein tolles Gefühl vermitteln. Ob es sich um die Gestaltung von Werbebannern, der Store-Seite oder um die Programmierung der zugehörigen Website geht – wir zaubern den Benutzern gerne ein Lächeln, nein, ein breites Grinsen ins Gesicht.</p>
            <div className="mb-5 mt-5">
              <Quote continuation author="Steve Jobs" text="Wir lassen die Knöpfe auf dem Bildschirm so gut aussehen, dass du sie ablecken willst." />
            </div>
          </AreaItem>

          <AreaItem title="3. CODE">
            {/* <span>
              {"var primary = ['JavaScript', 'HTML & CSS', 'Dart']"}
            </span> */}
            <h3>Die Zukunft im Blick</h3>
            <p>Technologie verändert sich ständig & rapide, aber neu heißt nicht immer besser, oder gut. <br />Aber welche Technologie ist dann die richtige? Wir sparen Zeit durch unsere Erfahrung und intelligente Kompromisse.</p>
            {/* bild mit plan (horizontal liste) */}
            <h3>Alles nach Plan</h3>
            <p>Wenn die Technologie feststeht, machen wir uns daran, Ihre App umzusetzen. Gerne halten wir sie auf dem Laufenden und liefern Ihnen regelmäßig Testversionen.
              Das geht so lange, bis die App alle Ihre Erwartungen übertrifft. <br />Anschließend helfen wir Ihnen bei der Veröffentlichung.</p>
            <p></p>
          </AreaItem>

          <AreaItem title="4. ERFOLG">
            <img className="w-100" src={AppleChartsImg} />
          </AreaItem>

          <br />
          <div className="d-flex flex-row justify-conent-center w-100 text-center">
            <Link to="/kontakt/">
              <button className="">Kontakt aufnehmen</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="container mt-5 pt-5">
        <h2>Preisgestaltung</h2>

        <table>
          <tr>
            <th>Aufgabe</th>
            <th >Stundensatz</th>
          </tr>
          <tr>
            <td>Erstberatung & Angebot</td>
            <td >0€</td>
          </tr>
          <tr>
            <td>Übertragung von Inhalten</td>
            <td >32€</td>
          </tr>
          <tr>
            <td>Texte</td>
            <td >64€</td>
          </tr>
          <tr>
            <td>Beratung</td>
            <td >120€</td>
          </tr>
          <tr>
            <td>Design (UI & UX)</td>
            <td >120€</td>
          </tr>
          <tr>
            <td>Entwicklung (Client, API, SQL)</td>
            <td >128€</td>
          </tr>
        </table>

        <div className="mx-2 mt-2">
          <small>Alle Preise sind Netto Preise.</small>
        </div>

        {/* <h3>Kommunale Apps</h3> */}

      </div>

    </TextPage >
  );
}

export default AppsPage;



const data = {
  fancyImage: "https://blog.dashdesign.eu/wp-content/uploads/2022/01/Dashdesign-asp-büro_IB-reinigung-website.png",
  ddFeatures: [
    {
      title: "Plattformunabhängigkeit",
      text: <>Durch die Entwicklung einer einzigen, plattformunabhängigen App sparen Sie Kosten im Vergleich zur Entwicklung von separaten Apps für jedes Betriebssystem. Trotzdem profitieren Sie von einer App, die optimal auf jedes System abgestimmt ist und alle Funktionen bereitstellt, die Sie benötigen.</>,
      icon: <i className="fad  fa-rabbit-fast"></i>,

    },
    {
      title: "Günstige Projekte mit Reused-Code",
      text: <>Sparen Sie Kosten bei der Entwicklung Ihrer App indem wir bereits vorhandene Code-Bausteine wiederverwenden. Dadurch entstehen günstigere Projekte ohne Abstriche bei Qualität und Funktionalität.</>,
      icon: <i class="fa-duotone fa-rocket-launch"></i>,
    },
    {
      title: "Over-the-air Updates",
      text: <>Mit dashdesign; haben Sie die Möglichkeit, Ihre App jederzeit zu aktualisieren. Unabhängig von langen Prüfzeiten bei Apple, Google und Co.</>,
      icon: <i className="fad  fa-phone-laptop"></i>,
    },
  ],
  features: [
    {
      title: "Umfassende Kundenbetreuung",
      icon: <i class="fa-duotone fa-user-headset"></i>,
      description: "Erfahrene Mitarbeiter stehen jederzeit zur Verfügung.",
    },
    {
      title: "Zukunftssicherheit",
      icon: <i class="fa-duotone fa-robot"></i>,
      description: "Fokus auf neue Technologien für eine nachhaltige Lösung.",
    },
    {
      title: "Integration mit Ihren Systemen",
      icon: <i className="fad  fa-sparkles"></i>,
      description: "Einfache Integration in bestehende Systeme.",
    },
    {
      title: "Über 10 Jahre Erfahrung",
      description: "Über 10 Jahre Expertise in der Entwicklung.",
      icon: <i class="fa-duotone fa-brain-circuit"></i>,
    },
    {
      title: "Individuelle Softwarelösungen",
      icon: <i className="fad  fa-code"></i>,
    },
    {
      title: "Festpreis / Stundensatz",
      icon: <i class="fad fa-euro-sign"></i>,
    },
    {
      title: "Unabhängig von Drittanbietern",
      icon: <i className="fad  fa-people-carry"></i>,
    },
    {
      title: "Design, das sich durchsetzen kann",
      icon: <i class="fa-duotone fa-pen-paintbrush"></i>,
    },
    {
      title: "Anpassbare Inhalte ohne Programmierkenntnisse (CMS)",
      icon: <i class="fa-duotone fa-pen-paintbrush"></i>,
    },
    {
      title: "Kreative Konzepte inklusive",
      icon: <i class="fa-duotone fa-lightbulb"></i>,
    },
  ]
}


function AreaItem(props) {
  return (
    <div className="row mt-5 AreaItem">
      <div className="col-12 col-md-4 p-5">
        <span className="bigTag">{props.title}</span>
      </div>
      <div className="col-12 col-md-8 p-5" style={{ borderLeft: "12px solid var(--dpBlue)" }}>
        {props.children}
      </div>
    </div>
  )
}