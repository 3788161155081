import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "../container/about/AboutPage";
import AppsPage from "../container/development/apps/AppsPage";
import PlatformPage from "../container/development/PlatformPage/PlatformPage";
import WebsitesPage from "../container/development/websites/WebsitesPage";
import HomePage from "../container/home/HomePage";
import DatenschutzPage from "../container/law/DatenschutzPage";
import ImpressumPage from "../container/law/ImpressumPage";
import ContactPage from "../container/more/ContactPage";
import JobsPage from "../container/more/JobsPage";
import Page404 from "../container/more/Page404";
import PartnerPage from "../container/more/PartnerPage";
import ReferenzPage from "../container/more/ReferenzPage";
import ServerStatus from "../container/more/ServerStatus/ServerStatus";
import SupportPage from "../container/more/SupportPage";
import TeamPage from "../container/more/TeamPage";
import TerminPage from "../container/more/TerminPage";
import UmweltschutzPage from "../container/more/UmweltschutzPage";
import ProjectSinglePage from "../container/projects/ProjectSinglePage/ProjectSinglePage";

function NavigationProvider() {
  return (
    <>
      <Routes>
        <Route path="/" index element={<HomePage />} />

        <Route path="/partner/" element={<PartnerPage />} />
        <Route path="/about/" element={<AboutPage />} />
        <Route path="/server-status/" element={<ServerStatus />} />
        <Route path="/team/" element={<TeamPage />} />
        <Route path="/support/" element={<SupportPage />} />
        <Route path="/umweltschutz/" element={<UmweltschutzPage />} />
        <Route path="/karriere/" element={<JobsPage />} />
        <Route path="/kontakt/" element={<ContactPage />} />
        <Route path="/termin/" element={<TerminPage />} />
        <Route path="/umweltschutz/" element={<UmweltschutzPage />} />
        <Route path="/karriere/" element={<JobsPage />} />

        <Route path="/datenschutz/" element={<DatenschutzPage />} />
        <Route path="/impressum/" element={<ImpressumPage />} />


        <Route path="/projekte/" index element={<ReferenzPage />} />
        <Route path={"/projekt"} >
          <Route path="/projekt/:slug" index element={<ProjectSinglePage />} />
        </Route>

        <Route path={"/entwicklung"} >
          <Route path="/entwicklung/web" element={<WebsitesPage />} />
          <Route path="/entwicklung/app" element={<AppsPage />} />
          <Route path="/entwicklung/platform" element={<PlatformPage />} />
          <Route path="/entwicklung/plattform" element={<PlatformPage />} />
        </Route>

        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}
export default NavigationProvider;