import React, { useEffect, useState } from "react"
import "./SubBox.scss"

function SubBox({ children }) {
    return (
        <div className="SubBox">
            {children}
        </div>
    )
}
export default SubBox