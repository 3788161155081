import React from "react"
import { useApi } from "../../../../services/useApi"
import PageLoader from "../../../defaults/loader/PageLoader"

function ListRender({ endpoint, render, loader = <PageLoader />, error = <p>Fehler beim laden.</p>, className }) {
    const { Data, Loading, Error } = useApi(endpoint)

    if (Error) return error
    if (Loading) return loader
    if (Data) {
        let value = Data

        console.log(Data)

        if (Array.isArray(Data) && Data.length > 0) {
            value = Data
            let result = []

            value.forEach(element => {
                result.push(render(element))
            });

            return <div className={className}>{result}</div>
        }
    }
}
export default ListRender