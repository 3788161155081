import React from "react"
import GoBackButton from "../buttons/GobackButton"
import "./PageTitle.scss"

function PageTitle({ children, subTitle, noGoBack }) {
    return (
        <div className="PageTitle">

            <div className="inner d-flex flex-column flex-md-row pt-0 pt-lg-5">
                {noGoBack ? null :
                    <GoBackButton>
                        <i className="icon fa-solid fa-chevron-circle-left" />
                    </GoBackButton>
                }
                <h1 className="mb-0 pb-0">{children}</h1>
            </div>

            <span className="subTitle">{subTitle}</span>
        </div>
    )
}
export default PageTitle