import React from 'react'
import { Helmet } from 'react-helmet'
import TextPage from '../../components/pages/TextPage'

function DatenschutzPage() {
  return (
    <TextPage title="Datenschutz­erklärung">
      <Helmet>
        <title>Datenschutz­erklärung </title>
        <meta name="description" content="Die Datenschutz­erklärung unserer Website." />
      </Helmet>

      <>
        <h1>Datenschutz­erklärung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>{" "}
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
          Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
          Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
          identifiziert werden können. Ausführliche Informationen zum Thema
          Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>{" "}
        <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>{" "}
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
          Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen
          Stelle“ in dieser Datenschutzerklärung entnehmen.
        </p>{" "}
        <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
          Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>{" "}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der
          Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten
          (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
          Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
          betreten.
        </p>{" "}
        <h4>Wofür nutzen wir Ihre Daten?</h4>{" "}
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
          Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>{" "}
        <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>{" "}
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
          Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu
          verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
          haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht
          Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>{" "}
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
        </p>
        <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>{" "}
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet
          werden. Das geschieht vor allem mit sogenannten Analyseprogrammen.
        </p>{" "}
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der
          folgenden Datenschutzerklärung.
        </p>
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Hetzner</h3>{" "}
        <p>
          Wir hosten unsere Website bei Hetzner. Anbieter ist die Hetzner Online GmbH,
          Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).
        </p>{" "}
        <p>
          Details entnehmen Sie der Datenschutzerklärung von Hetzner:{" "}
          <a
            href="https://www.hetzner.com/de/rechtliches/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.hetzner.com/de/rechtliches/datenschutz
          </a>
          .
        </p>{" "}
        <p>
          Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen
          Darstellung unserer Website. Sofern eine entsprechende Einwilligung
          abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung
          die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
          des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>
        <h4>Auftragsverarbeitung</h4>{" "}
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
          Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <h3>Cloudflare</h3>{" "}
        <p>
          Wir nutzen den Service „Cloudflare“. Anbieter ist die Cloudflare Inc., 101
          Townsend St., San Francisco, CA 94107, USA (im Folgenden „Cloudflare”).
        </p>{" "}
        <p>
          Cloudflare bietet ein weltweit verteiltes Content Delivery Network mit DNS
          an. Dabei wird technisch der Informationstransfer zwischen Ihrem Browser und
          unserer Website über das Netzwerk von Cloudflare geleitet. Das versetzt
          Cloudflare in die Lage, den Datenverkehr zwischen Ihrem Browser und unserer
          Website zu analysieren und als Filter zwischen unseren Servern und
          potenziell bösartigem Datenverkehr aus dem Internet zu dienen. Hierbei kann
          Cloudflare auch Cookies oder sonstige Technologien zur Wiedererkennung von
          Internetnutzern einsetzen, die jedoch allein zum hier beschriebenen Zweck
          verwendet werden.
        </p>{" "}
        <p>
          Der Einsatz von Cloudflare beruht auf unserem berechtigten Interesse an
          einer möglichst fehlerfreien und sicheren Bereitstellung unseres
          Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.cloudflare.com/privacypolicy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cloudflare.com/privacypolicy/
          </a>
          .
        </p>{" "}
        <p>
          Weitere Informationen zum Thema Sicherheit und Datenschutz bei Cloudflare
          finden Sie hier:{" "}
          <a
            href="https://www.cloudflare.com/privacypolicy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cloudflare.com/privacypolicy/
          </a>
          .
        </p>
        <h4>Auftragsverarbeitung</h4>{" "}
        <p>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben
          genannten Anbieter geschlossen. Hierbei handelt es sich um einen
          datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
          dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
          Weisungen und unter Einhaltung der DSGVO verarbeitet.
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
        <h3>Datenschutz</h3>{" "}
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
          ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>{" "}
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
          erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
          identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert,
          welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
          und zu welchem Zweck das geschieht.
        </p>{" "}
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        </p>{" "}
        <p>
          Hessen App GmbH
          <br />
          Geschäftsführer Konrad ;Mayer
          <br />
          Am alten Turm 1<br />
          35305 Grünberg
        </p>
        <p>
          Telefon: 06401 / 22 90 437<br />
          E-Mail: info@dashdesign.eu
        </p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3>Speicherdauer</h3>{" "}
        <p>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer
          genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der
          Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
          Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung
          widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich
          zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben
          (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
          letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <h3>
          Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
          Website
        </h3>{" "}
        <p>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre
          personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw.
          Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9
          Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung
          in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die
          Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO.
          Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf
          Informationen in Ihr Endgerät (z.&nbsp;B. via Device-Fingerprinting)
          eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage
          von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre
          Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1
          lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
          Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage
          von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
          Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO
          erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird
          in den folgenden Absätzen dieser Datenschutzerklärung informiert.
        </p>
        <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>{" "}
        <p>
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder
          sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools
          aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten
          übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in
          diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert
          werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
          personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie
          als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Behörden (z.&nbsp;B. Geheimdienste) Ihre auf
          US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten
          und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen
          Einfluss.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
          Direktwerbung (Art. 21 DSGVO)
        </h3>{" "}
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
          DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
          IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF
          DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF
          DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
          WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
          DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE
          SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN,
          RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
          GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH
          NACH ART. 21 ABS. 1 DSGVO).
        </p>{" "}
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
          WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
          DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
          PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
          DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>{" "}
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des
          Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten­übertrag­barkeit</h3>{" "}
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
          Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
          Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
          Sofern Sie die direkte Übertragung der Daten an einen anderen
          Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
          ist.
        </p>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>{" "}
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
          vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie
          an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
          verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
          Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
          Ihrer Browserzeile.
        </p>{" "}
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die
          Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>{" "}
        <p>
          Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
          Verpflichtung, uns Ihre Zahlungsdaten (z.&nbsp;B. Kontonummer bei
          Einzugsermächtigung) zu übermitteln, werden diese Daten zur
          Zahlungsabwicklung benötigt.
        </p>{" "}
        <p>
          Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard,
          Lastschriftverfahren) erfolgt ausschließlich über eine verschlüsselte SSL-
          bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass
          die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an
          dem Schloss-Symbol in Ihrer Browserzeile.
        </p>{" "}
        <p>
          Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns
          übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h3>Auskunft, Löschung und Berichtigung</h3>{" "}
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
          Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
          Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
          ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
          an uns wenden.
        </p>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>{" "}
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an
          uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
        </p>{" "}
        <ul>
          {" "}
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
            Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen.
            Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{" "}
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
            geschah/geschieht, können Sie statt der Löschung die Einschränkung der
            Datenverarbeitung verlangen.
          </li>{" "}
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch
            zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen
            benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{" "}
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
            eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
            Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
            Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
            verlangen.
          </li>{" "}
        </ul>{" "}
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
          dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
          Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
          juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
          der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
          und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
          Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
          unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>{" "}
        <p>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine
          Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden
          entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder
          dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert.
          Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht.
          Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
          selbst löschen&nbsp;oder eine automatische Löschung durch Ihren Webbrowser
          erfolgt.
        </p>{" "}
        <p>
          Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
          gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies).
          Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des
          Drittunternehmens (z.&nbsp;B. Cookies zur Abwicklung von
          Zahlungsdienstleistungen).
        </p>{" "}
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
          notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
          würden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige von Videos).
          Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder
          Werbung anzuzeigen.
        </p>{" "}
        <p>
          Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur
          Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.&nbsp;B. für
          die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B. Cookies
          zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden
          auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
          andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
          berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
          technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern
          eine Einwilligung zur Speicherung von Cookies und vergleichbaren
          Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO
          und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
          informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von
          Cookies für bestimmte Fälle oder generell ausschließen sowie das
          automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei
          der Deaktivierung von Cookies kann die Funktionalität dieser Website
          eingeschränkt sein.
        </p>{" "}
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
          gesondert informieren und ggf. eine Einwilligung abfragen.
        </p>
        <h3>Einwilligung mit Usercentrics</h3>{" "}
        <p>
          Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre
          Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder zum
          Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu
          dokumentieren. Anbieter dieser Technologie ist die Usercentrics GmbH,
          Sendlinger Straße 7, 80331 München, Website:{" "}
          <a
            href="https://usercentrics.com/de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://usercentrics.com/de/
          </a>{" "}
          (im Folgenden „Usercentrics“).
        </p>{" "}
        <p>
          Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten an
          Usercentrics übertragen:
        </p>{" "}
        <ul>
          {" "}
          <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>{" "}
          <li>Ihre IP-Adresse</li> <li>Informationen über Ihren Browser</li>{" "}
          <li>Informationen über Ihr Endgerät</li>{" "}
          <li>Zeitpunkt Ihres Besuchs auf der Website</li>{" "}
        </ul>{" "}
        <p>
          Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen
          die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können. Die so
          erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, das
          Usercentrics-Cookie selbst löschen oder der Zweck für die Datenspeicherung
          entfällt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.
        </p>{" "}
        <p>
          Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
          Einwilligungen für den Einsatz bestimmter Technologien einzuholen.
          Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
        </p>
        <h3>Server-Log-Dateien</h3>{" "}
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen in so
          genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          übermittelt. Dies sind:
        </p>{" "}
        <ul>
          {" "}
          <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>{" "}
          <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li>{" "}
          <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
        </ul>{" "}
        <p>
          Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
          vorgenommen.
        </p>{" "}
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch
          fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen
          die Server-Log-Files erfasst werden.
        </p>
        <h3>Kontaktformular</h3>{" "}
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
          Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
          Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>{" "}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
          DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
          oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
          übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
          der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis
          Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
          abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
          Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
          Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
          verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
        </p>{" "}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
          DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
          oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen
          übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
          der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1
          lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei
          uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
          abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
          Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
          unberührt.
        </p>
        <h3>Kommunikation via WhatsApp</h3>{" "}
        <p>
          Für die Kommunikation mit unseren Kunden und sonstigen Dritten nutzen wir
          unter anderem den Instant-Messaging-Dienst WhatsApp. Anbieter ist die
          WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
          2, Irland.
        </p>{" "}
        <p>
          Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung
          (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte Zugriff
          auf die Kommunikationsinhalte erlangen können. WhatsApp erhält jedoch
          Zugriff auf Metadaten, die im Zuge des Kommunikationsvorgangs entstehen
          (z.&nbsp;B. Absender, Empfänger und Zeitpunkt). Wir weisen ferner darauf
          hin, dass WhatsApp nach eigener Aussage, personenbezogene Daten seiner
          Nutzer mit seiner in den USA ansässigen Konzernmutter Facebook teilt.
          Weitere Details zur Datenverarbeitung finden Sie in der
          Datenschutzrichtlinie von WhatsApp unter:{" "}
          <a
            href="https://www.whatsapp.com/legal/#privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.whatsapp.com/legal/#privacy-policy
          </a>
          .
        </p>{" "}
        <p>
          Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten
          Interesses an einer möglichst schnellen und effektiven Kommunikation mit
          Kunden, Interessenten und sonstigen Geschäfts- und Vertragspartnern (Art. 6
          Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt
          wurde, erfolgt die Datenverarbeitung ausschließlich auf Grundlage der
          Einwilligung; diese ist jederzeit mit Wirkung für die Zukunft widerrufbar.
        </p>{" "}
        <p>
          Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte
          verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
          zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
          (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
          gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
          unberührt.
        </p>
        <p>Wir nutzen WhatsApp in der Variante „WhatsApp Business“.</p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.whatsapp.com/legal/business-data-transfer-addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.whatsapp.com/legal/business-data-transfer-addendum
          </a>
          .
        </p>
        <h3>Calendly</h3>{" "}
        <p>
          Auf unserer Website haben Sie die Möglichkeit, Termine mit uns zu
          vereinbaren. Für die Terminbuchung nutzen wir das Tool „Calendly“. Anbieter
          ist die Calendly LLC, 271 17th St NW, 10th Floor, Atlanta, Georgia 30363,
          USA (nachfolgend „Calendly“).
        </p>{" "}
        <p>
          Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und den
          Wunschtermin in die dafür vorgesehene Maske ein. Die eingegebenen Daten
          werden für die Planung, Durchführung und ggf. für die Nachbereitung des
          Termins verwendet. Die Termindaten werden für uns auf den Servern von
          Calendly gespeichert, dessen Datenschutzerklärung Sie hier einsehen können:{" "}
          <a
            href="https://calendly.com/de/pages/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://calendly.com/de/pages/privacy
          </a>
          .
        </p>{" "}
        <p>
          Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns zur
          Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
          Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen
          – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </p>{" "}
        <p>
          Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.
          Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst
          unkomplizierten Terminvereinbarung mit Interessenten und Kunden. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://calendly.com/pages/dpa"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://calendly.com/pages/dpa
          </a>
          .
        </p>
        <h3>Registrierung mit Google</h3>{" "}
        <p>
          Statt einer direkten Registrierung auf dieser Website können Sie sich mit
          Google registrieren. Anbieter dieses Dienstes ist die Google Ireland Limited
          („Google”), Gordon House, Barrow Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Um sich mit Google zu registrieren, müssen Sie ausschließlich Ihre
          Google-Namen und Ihr Passwort eingeben. Google wird Sie identifizieren und
          unserer Website Ihre Identität bestätigen.
        </p>{" "}
        <p>
          Wenn Sie sich mit Google anmelden, ist es uns ggf. möglich bestimmte
          Informationen auf Ihrem Konto zu nutzen, um Ihr Profil bei uns zu
          vervollständigen. Ob und welche Informationen das sind, entscheiden Sie im
          Rahmen Ihrer Google-Sicherheitseinstellungen, die Sie hier finden:{" "}
          <a
            href="https://myaccount.google.com/security"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://myaccount.google.com/security
          </a>{" "}
          und{" "}
          <a
            href="https://myaccount.google.com/permissions"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://myaccount.google.com/permissions
          </a>
          .
        </p>{" "}
        <p>
          Die Datenverarbeitung, die mit der Google-Registrierung einhergeht beruht
          auf unserem berechtigten Interesse, unseren Nutzern einen möglichst
          einfachen Registrierungsprozess zu ermöglichen (Art. 6 Abs. 1 lit. f DSGVO).
          Da die Nutzung der Registrierungsfunktion freiwillig ist und die Nutzer
          selbst über die jeweiligen Zugriffsmöglichkeiten entscheiden können, sind
          keine entgegenstehenden überwiegenden Rechte der Betroffenen ersichtlich.
        </p>
        <h3>Kommentar­funktion auf dieser Website</h3>{" "}
        <p>
          Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch
          Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse
          und, wenn Sie nicht anonym posten, der von Ihnen gewählte Nutzername
          gespeichert.
        </p>
        <h4>Speicherung der IP-Adresse</h4>{" "}
        <p>
          Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
          Kommentare verfassen. Da wir Kommentare auf dieser Website nicht vor der
          Freischaltung prüfen, benötigen wir diese Daten, um im Falle von
          Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser
          vorgehen zu können.
        </p>
        <h4>Abonnieren von Kommentaren</h4>{" "}
        <p>
          Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare abonnieren.
          Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der
          angegebenen E-Mail-Adresse sind. Sie können diese Funktion jederzeit über
          einen Link in den Info-Mails abbestellen. Die im Rahmen des Abonnierens von
          Kommentaren eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie
          diese Daten für andere Zwecke und an anderer Stelle (z.&nbsp;B.
          Newsletterbestellung) an uns übermittelt haben, verbleiben diese Daten
          jedoch bei uns.
        </p>
        <h4>Speicherdauer der Kommentare</h4>{" "}
        <p>
          Die Kommentare und die damit verbundenen Daten werden gespeichert und
          verbleiben auf dieser Website, bis der kommentierte Inhalt vollständig
          gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden
          müssen (z.&nbsp;B. beleidigende Kommentare).
        </p>
        <h4>Rechtsgrundlage</h4>{" "}
        <p>
          Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung
          (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte
          Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
          E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <h2>5. Soziale Medien</h2>
        <h3>Facebook Plugins (Like &amp; Share-Button)</h3>{" "}
        <p>
          Auf dieser Website sind Plugins des sozialen Netzwerks Facebook integriert.
          Anbieter dieses Dienstes ist die Meta Platforms Ireland Limited, 4 Grand
          Canal Square, Dublin 2, Irland. Die erfassten Daten werden nach Aussage von
          Facebook jedoch auch in die USA und in andere Drittländer übertragen.
        </p>{" "}
        <p>
          Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
          „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht über die
          Facebook Plugins finden Sie hier:{" "}
          <a
            href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins/?locale=de_DE
          </a>
          .
        </p>{" "}
        <p>
          Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
          Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
          Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese
          Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken,
          während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die
          Inhalte dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann
          Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
          darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
          Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook
          unter:{" "}
          <a
            href="https://de-de.facebook.com/privacy/explanation"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/privacy/explanation
          </a>
          .
        </p>{" "}
        <p>
          Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website Ihrem
          Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
          Facebook-Benutzerkonto aus.
        </p>{" "}
        <p>
          Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
          möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf
          unserer Website erfasst und an Facebook weitergeleitet werden, sind wir und
          die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal
          Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung
          verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt
          sich dabei ausschließlich auf die Erfassung der Daten und deren Weitergabe
          an Facebook. Die nach der Weiterleitung erfolgende Verarbeitung durch
          Facebook ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam
          obliegenden Verpflichtungen wurden in einer Vereinbarung über gemeinsame
          Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:{" "}
          <a
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </a>
          . Laut dieser Vereinbarung sind wir für die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
          datenschutzrechtlich sichere Implementierung des Tools auf unserer Website
          verantwortlich. Für die Datensicherheit der Facebook-Produkte ist Facebook
          verantwortlich. Betroffenenrechte (z.&nbsp;B. Auskunftsersuchen)
          hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei
          Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend
          machen, sind wir verpflichtet, diese an Facebook weiterzuleiten.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ,{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>{" "}
          und{" "}
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h3>Twitter Plugin</h3>{" "}
        <p>
          Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden. Diese
          Funktionen werden angeboten durch die Twitter International Company, One
          Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Irland. Durch das
          Benutzen von Twitter und der Funktion „Re-Tweet“ werden die von Ihnen
          besuchten Websites mit Ihrem Twitter-Account verknüpft und anderen Nutzern
          bekannt gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen
          darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere
          Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter
          unter:{" "}
          <a
            href="https://twitter.com/de/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/de/privacy
          </a>
          .
        </p>{" "}
        <p>
          Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
          möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://gdpr.twitter.com/en/controller-to-controller-transfers.html
          </a>
          .
        </p>{" "}
        <p>
          Ihre Datenschutzeinstellungen bei Twitter können Sie in den
          Konto-Einstellungen unter{" "}
          <a
            href="https://twitter.com/account/settings"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/account/settings
          </a>{" "}
          ändern.
        </p>
        <h3>Instagram Plugin</h3>{" "}
        <p>
          Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese
          Funktionen werden angeboten durch die Meta Platforms Ireland Limited, 4
          Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland integriert.
        </p>{" "}
        <p>
          Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch
          Anklicken des Instagram-Buttons die Inhalte dieser Website mit Ihrem
          Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser Website
          Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter
          der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
          Nutzung durch Instagram erhalten.
        </p>{" "}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
          möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf
          unserer Website erfasst und an Facebook bzw. Instagram weitergeleitet
          werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal
          Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
          Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
          Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung
          der Daten und deren Weitergabe an Facebook bzw. Instagram. Die nach der
          Weiterleitung erfolgende Verarbeitung durch Facebook bzw. Instagram ist
          nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
          Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung
          festgehalten. Den Wortlaut der Vereinbarung finden Sie unter:{" "}
          <a
            href="https://www.facebook.com/legal/controller_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/controller_addendum
          </a>
          . Laut dieser Vereinbarung sind wir für die Erteilung der
          Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und
          für die datenschutzrechtlich sichere Implementierung des Tools auf unserer
          Website verantwortlich. Für die Datensicherheit der Facebook bzw.
          Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte
          (z.&nbsp;B. Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram
          verarbeiteten Daten können Sie direkt bei Facebook geltend machen. Wenn Sie
          die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese
          an Facebook weiterzuleiten.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ,{" "}
          <a
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>{" "}
          und{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>
          .
        </p>{" "}
        <p>
          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
          Instagram:{" "}
          <a
            href="https://instagram.com/about/legal/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://instagram.com/about/legal/privacy/
          </a>
          .
        </p>
        <h3>LinkedIn Plugin</h3>{" "}
        <p>
          Diese Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die
          LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2,
          Irland.
        </p>{" "}
        <p>
          Bei jedem Abruf einer Seite dieser Website, die Funktionen von LinkedIn
          enthält, wird eine Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn
          wird darüber informiert, dass Sie diese Website mit Ihrer IP-Adresse besucht
          haben. Wenn Sie den „Recommend-Button“ von LinkedIn anklicken und in Ihrem
          Account bei LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch
          auf dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir weisen
          darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          übermittelten Daten sowie deren Nutzung durch LinkedIn haben.
        </p>{" "}
        <p>
          Die Verwendung des LinkedIn-Plugins erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
          möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de
          </a>
        </p>{" "}
        <p>
          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
          LinkedIn unter:{" "}
          <a
            href="https://www.linkedin.com/legal/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/legal/privacy-policy
          </a>
          .
        </p>
        <h3>XING Plugin</h3>{" "}
        <p>
          Diese Website nutzt Funktionen des Netzwerks XING. Anbieter ist die New Work
          SE, Dammtorstraße 30, 20354 Hamburg, Deutschland.
        </p>{" "}
        <p>
          Bei jedem Abruf einer unserer Seiten, die Funktionen von XING enthält, wird
          eine Verbindung zu Servern von XING hergestellt. Eine Speicherung von
          personenbezogenen Daten erfolgt dabei nach unserer Kenntnis nicht.
          Insbesondere werden keine IP-Adressen gespeichert oder das Nutzungsverhalten
          ausgewertet.
        </p>{" "}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer
          möglichst umfangreichen Sichtbarkeit in den Sozialen Medien. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Weitere Information zum Datenschutz und dem XING Share-Button finden Sie in
          der Datenschutzerklärung von XING unter:{" "}
          <a
            href="https://www.xing.com/app/share?op=data_protection"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.xing.com/app/share?op=data_protection
          </a>
          .
        </p>
        <h2>6. Analyse-Tools und Werbung</h2>
        <h3>Google Analytics</h3>{" "}
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
          Anbieter ist die Google Ireland Limited („Google“), Gordon House, Barrow
          Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten der
          Websitebesucher zu analysieren. Hierbei erhält der Websitebetreiber
          verschiedene Nutzungsdaten, wie z.&nbsp;B. Seitenaufrufe, Verweildauer,
          verwendete Betriebssysteme und Herkunft des Nutzers. Diese Daten werden in
          einer User-ID zusammengefasst und dem jeweiligen Endgerät des
          Websitebesuchers zugeordnet.
        </p>{" "}
        <p>
          Des Weiteren können wir mit Google Analytics u.&nbsp;a. Ihre Maus- und
          Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet Google Analytics
          verschiedene Modellierungsansätze, um die erfassten Datensätze zu ergänzen
          und setzt Machine-Learning-Technologien bei der Datenanalyse ein.
        </p>
        <p>
          Google Analytics verwendet Technologien, die die Wiedererkennung des Nutzers
          zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.&nbsp;B. Cookies
          oder Device-Fingerprinting). Die von Google erfassten Informationen über die
          Benutzung dieser Website werden in der Regel an einen Server von Google in
          den USA übertragen und dort gespeichert.
        </p>{" "}
        <p>
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach
          Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist
          jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://privacy.google.com/businesses/controllerterms/mccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
          .
        </p>{" "}
        <h4>Browser Plugin</h4>{" "}
        <p>
          Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
          verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:{" "}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          .
        </p>{" "}
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden
          Sie in der Datenschutzerklärung von Google:{" "}
          <a
            href="https://support.google.com/analytics/answer/6004245?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
          .
        </p>
        <h4>Google-Signale</h4>{" "}
        <p>
          Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen, erfasst Google
          Analytics u.&nbsp;a. Ihren Standort, Suchverlauf und YouTube-Verlauf sowie
          demografische Daten (Besucherdaten). Diese Daten können mit Hilfe von
          Google-Signal für personalisierte Werbung verwendet werden. Wenn Sie über
          ein Google-Konto verfügen, werden die Besucherdaten von Google-Signal mit
          Ihrem Google-Konto verknüpft und für personalisierte Werbebotschaften
          verwendet. Die Daten werden außerdem für die Erstellung anonymisierter
          Statistiken zum Nutzerverhalten unserer User verwendet.
        </p>
        <h4>Auftragsverarbeitung</h4>{" "}
        <p>
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung abgeschlossen
          und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der
          Nutzung von Google Analytics vollständig um.
        </p>
        <h4>Google Analytics E-Commerce-Messung</h4>{" "}
        <p>
          Diese Website nutzt die Funktion „E-Commerce-Messung“ von Google Analytics.
          Mit Hilfe von E-Commerce-Messung kann der Websitebetreiber das Kaufverhalten
          der Websitebesucher zur Verbesserung seiner Online-Marketing-Kampagnen
          analysieren. Hierbei werden Informationen, wie zum Beispiel die getätigten
          Bestellungen, durchschnittliche Bestellwerte, Versandkosten und die Zeit von
          der Ansicht bis zum Kauf eines Produktes erfasst. Diese Daten können von
          Google unter einer Transaktions-ID zusammengefasst werden, die dem
          jeweiligen Nutzer bzw. dessen Gerät zugeordnet ist.
        </p>
        <h3>Google Ads</h3>{" "}
        <p>
          Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
          Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon House,
          Barrow Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine oder
          auf Drittwebseiten auszuspielen, wenn der Nutzer bestimmte Suchbegriffe bei
          Google eingibt (Keyword-Targeting). Ferner können zielgerichtete
          Werbeanzeigen anhand der bei Google vorhandenen Nutzerdaten (z.&nbsp;B.
          Standortdaten und Interessen) ausgespielt werden (Zielgruppen-Targeting).
          Wir als Websitebetreiber können diese Daten quantitativ auswerten, indem wir
          beispielsweise analysieren, welche Suchbegriffe zur Ausspielung unserer
          Werbeanzeigen geführt haben und wie viele Anzeigen zu entsprechenden Klicks
          geführt haben.
        </p>{" "}
        <p>
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach
          Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist
          jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://policies.google.com/privacy/frameworks"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy/frameworks
          </a>{" "}
          und{" "}
          <a
            href="https://privacy.google.com/businesses/controllerterms/mccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
          .
        </p>
        <h2>7. Newsletter</h2>
        <h3>Newsletter­daten</h3>{" "}
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
          benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns
          die Überprüfung gestatten, dass Sie der Inhaber der angegebenen
          E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind.
          Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese
          Daten verwenden wir ausschließlich für den Versand der angeforderten
          Informationen und geben diese nicht an Dritte weiter.
        </p>{" "}
        <p>
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten
          erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit.
          a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der
          E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie
          jederzeit widerrufen, etwa über den „Austragen“-Link im Newsletter. Die
          Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
          Widerruf unberührt.
        </p>{" "}
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten
          werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem
          Newsletterdiensteanbieter gespeichert und nach der Abbestellung des
          Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste
          gelöscht. Wir behalten uns vor, E-Mail-Adressen aus unserem
          Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten
          Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
        </p>{" "}
        <p>
          Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon
          unberührt.
        </p>{" "}
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer
          Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings
          erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck
          verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
          Ihrem Interesse als auch unserem Interesse an der Einhaltung der
          gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse
          im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist
          ist zeitlich nicht befristet.{" "}
          <strong>
            Sie können der Speicherung widersprechen, sofern Ihre Interessen unser
            berechtigtes Interesse überwiegen.
          </strong>
        </p>
        <h2>8. Plugins und Tools</h2>
        <h3>YouTube mit erweitertem Datenschutz</h3>{" "}
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die
          Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4,
          Irland.
        </p>{" "}
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt
          laut YouTube, dass YouTube keine Informationen über die Besucher auf dieser
          Website speichert, bevor diese sich das Video ansehen. Die Weitergabe von
          Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus
          hingegen nicht zwingend ausgeschlossen. So stellt YouTube – unabhängig
          davon, ob Sie sich ein Video ansehen – eine Verbindung zum Google
          DoubleClick-Netzwerk her.
        </p>{" "}
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
          YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie
          in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr
          Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie
          verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
        </p>{" "}
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf
          Ihrem Endgerät speichern oder vergleichbare Wiedererkennungstechnologien
          (z.&nbsp;B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube
          Informationen über Besucher dieser Website erhalten. Diese Informationen
          werden u.&nbsp;a. verwendet, um Videostatistiken zu erfassen, die
          Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
        </p>{" "}
        <p>
          Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss
          haben.
        </p>{" "}
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung
          unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
          Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
          abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung
          die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
          des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Weitere Informationen über Datenschutz bei YouTube finden Sie in deren
          Datenschutzerklärung unter:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Google Web Fonts</h3>{" "}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte
          Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite
          lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte
          und Schriftarten korrekt anzuzeigen.
        </p>{" "}
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
          Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
          dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von
          Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
          Websitebetreiber hat ein berechtigtes Interesse an der einheitlichen
          Darstellung des Schriftbildes auf seiner Website. Sofern eine entsprechende
          Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
          Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die
          Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen
          im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des
          TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von
          Ihrem Computer genutzt.
        </p>{" "}
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Font Awesome</h3>{" "}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten und
          Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6 Porter Road
          Apartment 3R, Cambridge, Massachusetts, USA.
        </p>{" "}
        <p>
          Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in ihren
          Browsercache, um Texte, Schriftarten und Symbole korrekt anzuzeigen. Zu
          diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
          von Font Awesome aufnehmen. Hierdurch erlangt Font Awesome Kenntnis darüber,
          dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die Nutzung von
          Font Awesome erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
          ein berechtigtes Interesse an der einheitlichen Darstellung des
          Schriftbildes auf unserer Website. Sofern eine entsprechende Einwilligung
          abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung
          die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
          des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine Standardschrift
          von Ihrem Computer genutzt.
        </p>{" "}
        <p>
          Weitere Informationen zu Font Awesome finden Sie&nbsp;und in der
          Datenschutzerklärung von Font Awesome unter:{" "}
          <a
            href="https://fontawesome.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://fontawesome.com/privacy
          </a>
          .
        </p>
        <h3>Google Maps</h3>{" "}
        <p>
          Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google
          Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse
          zu speichern. Diese Informationen werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite
          hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert
          ist, kann Google zum Zwecke der einheitlichen Darstellung der Schriftarten
          Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die
          benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten
          korrekt anzuzeigen.
        </p>{" "}
        <p>
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der
          von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://privacy.google.com/businesses/gdprcontrollerterms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/gdprcontrollerterms/
          </a>{" "}
          und{" "}
          <a
            href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
          </a>
          .
        </p>{" "}
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerklärung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Spotify</h3>{" "}
        <p>
          Auf dieser Website sind Funktionen des Musik-Dienstes Spotify eingebunden.
          Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56 Stockholm in
          Schweden. Die Spotify Plugins erkennen Sie an dem grünen Logo auf dieser
          Website. Eine Übersicht über die Spotify-Plugins finden Sie unter:{" "}
          <a
            href="https://developer.spotify.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developer.spotify.com
          </a>
          .
        </p>{" "}
        <p>
          Dadurch kann beim Besuch dieser Website über das Plugin eine direkte
          Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt werden.
          Spotify erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse diese
          Website besucht haben. Wenn Sie den Spotify Button anklicken, während Sie in
          Ihrem Spotify-Account eingeloggt sind, können Sie die Inhalte dieser Website
          auf Ihrem Spotify Profil verlinken. Dadurch kann Spotify den Besuch dieser
          Website Ihrem Benutzerkonto zuordnen.
        </p>{" "}
        <p>
          Wir weisen darauf hin, dass bei der Nutzung von Spotify Cookies von Google
          Analytics eingesetzt werden, sodass Ihre Nutzungsdaten bei der Nutzung von
          Spotify auch an Google weitergegeben werden können. Google Analytics ist ein
          Tool des Google-Konzerns zur Analyse des Nutzerverhaltens mit Sitz in den
          USA. Für diese Einbindung ist allein Spotify verantwortlich. Wir als
          Websitebetreiber haben auf diese Verarbeitung keinen Einfluss.
        </p>{" "}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs.
          1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
          ansprechenden akustischen Ausgestaltung seiner Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von
          Spotify:{" "}
          <a
            href="https://www.spotify.com/de/legal/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.spotify.com/de/legal/privacy-policy/
          </a>
          .
        </p>{" "}
        <p>
          Wenn Sie nicht wünschen, dass Spotify den Besuch dieser Website Ihrem
          Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
          Spotify-Benutzerkonto aus.
        </p>
        <h3>tawk.to</h3>{" "}
        <p>
          Zur Bearbeitung von Nutzeranfragen über unsere Support-Kanäle oder über
          Live-Chat-Systeme verwenden wir tawk.to, inc., 187 E Warm Springs Rd, SB298,
          Las Vegas, Nevada 89119, USA (nachfolgend „tawk.to“).
        </p>{" "}
        <p>
          Nachrichten, die Sie an uns senden können im tawk.to-Ticketsystem
          gespeichert oder im Live-Chat von unseren Mitarbeitern beantwortet werden.
          Ferner können wir mit Hilfe von tawk.to können unter anderem feststellen,
          aus welcher Region der Anfragende kommt, wie lange er mit uns kommuniziert
          und wie zufrieden er mit dem Kommunikationsverlauf ist.
        </p>{" "}
        <p>
          Die an uns gerichteten Nachrichten verbleiben bei uns, bis Sie uns zur
          Löschung auffordern oder der Zweck für die Datenspeicherung entfällt
          (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
          gesetzliche Bestimmungen – insb. Aufbewahrungsfristen – bleiben unberührt.
        </p>{" "}
        <p>
          Die Verwendung von tawk.to erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst schnellen,
          zuverlässigen und effizienten Bearbeitung Ihrer Anfragen. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
          TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z.&nbsp;B. Device-Fingerprinting)
          im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://www.tawk.to/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tawk.to/privacy-policy/
          </a>{" "}
          und{" "}
          <a
            href="https://www.tawk.to/data-protection/gdpr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tawk.to/data-protection/gdpr/
          </a>
          .
        </p>{" "}
        <p>
          Weitere Informationen erhalten Sie in der Datenschutzerklärung von tawk.to:{" "}
          <a
            href="https://www.tawk.to/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tawk.to/privacy-policy/
          </a>{" "}
          und{" "}
          <a
            href="https://www.tawk.to/data-protection/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.tawk.to/data-protection/
          </a>
          .
        </p>
        <h2>9. eCommerce und Zahlungs­anbieter</h2>
        <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>{" "}
        <p>
          Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie
          für die Begründung, inhaltliche Ausgestaltung oder Änderung des
          Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
          Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
          Personenbezogene Daten über die Inanspruchnahme dieser Website
          (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies
          erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu
          ermöglichen oder abzurechnen.
        </p>{" "}
        <p>
          Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung
          der Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben
          unberührt.
        </p>
        <h3>
          Daten­übermittlung bei Vertragsschluss für Online-Shops, Händler und
          Warenversand
        </h3>{" "}
        <p>
          Wenn Sie Waren bei uns bestellen, geben wir Ihre personenbezogenen Daten an
          das zur Lieferung betraute Transportunternehmen sowie an den mit der
          Zahlungsabwicklung beauftragten Zahlungsdienstleister weiter. Es werden nur
          solche Daten herausgegeben, die der jeweilige Dienstleister zur Erfüllung
          seiner Aufgabe benötigt. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b
          DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet. Sofern Sie eine entsprechende
          Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO erteilt haben, werden wir Ihre
          E-Mail-Adresse an das mit der Lieferung betraute Transportunternehmen
          übergeben, damit dieses Sie per E-Mail über den Versandstatus Ihrer
          Bestellung informieren kann; Sie können die Einwilligung jederzeit
          widerrufen.
        </p>
        <h3>
          Daten­übermittlung bei Vertragsschluss für Dienstleistungen und digitale
          Inhalte
        </h3>{" "}
        <p>
          Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im
          Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
          Zahlungsabwicklung beauftragte Kreditinstitut.
        </p>{" "}
        <p>
          Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn
          Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
          Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der
          Werbung, erfolgt nicht.
        </p>{" "}
        <p>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die
          Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher
          Maßnahmen gestattet.
        </p>
        <h3>Bonitätsprüfungen</h3>{" "}
        <p>
          Bei einem Kauf auf Rechnung oder einer sonstigen Zahlungsart, bei der wir in
          Vorleistung gehen, können wir eine Bonitätsprüfung Verfahren durchführen
          (Scoring). Hierzu übermitteln wir Ihre eingegebenen Daten (z.&nbsp;B. Name,
          Adresse, Alter oder Bankdaten) an eine Auskunftei. Auf Grundlage dieser
          Daten wird die Wahrscheinlichkeit eines Zahlungsausfalls ermittelt. Bei
          einem überhöhten Zahlungsausfallrisiko können wir die betreffende
          Zahlungsart verweigern.
        </p>{" "}
        <p>
          Die Bonitätsprüfung erfolgt auf Grundlage zur Vertragserfüllung (Art. 6 Abs.
          1 lit. b DSGVO) sowie zur Vermeidung von Zahlungsausfällen (berechtigtes
          Interesse nach Art. 6 Abs. 1 lit. f DSGVO). Sofern eine Einwilligung
          eingeholt wurde, erfolgt die Bonitätsprüfung auf Grundlage dieser
          Einwilligung (Art. 6 Abs. 1 lit. DSGVO); die Einwilligung ist jederzeit
          widerrufbar.
        </p>
        <h2>10. Audio- und Videokonferenzen</h2>
        <h4>Datenverarbeitung</h4>{" "}
        <p>
          Für die Kommunikation mit unseren Kunden setzen wir unter anderen
          Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools sind
          unten aufgelistet. Wenn Sie mit uns per Video- oder Audiokonferenz via
          Internet kommunizieren, werden Ihre personenbezogenen Daten von uns und dem
          Anbieter des jeweiligen Konferenz-Tools erfasst und verarbeitet.
        </p>{" "}
        <p>
          Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der Tools
          bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre Telefonnummer). Ferner
          verarbeiten die Konferenz-Tools die Dauer der Konferenz, Beginn und Ende
          (Zeit) der Teilnahme an der Konferenz, Anzahl der Teilnehmer und sonstige
          „Kontextinformationen“ im Zusammenhang mit dem Kommunikationsvorgang
          (Metadaten).
        </p>{" "}
        <p>
          Des Weiteren verarbeitet der Anbieter des Tools alle technischen Daten, die
          zur Abwicklung der Online-Kommunikation erforderlich sind. Dies umfasst
          insbesondere IP-Adressen, MAC-Adressen, Geräte-IDs, Gerätetyp,
          Betriebssystemtyp und -version, Client-Version, Kameratyp, Mikrofon oder
          Lautsprecher sowie die Art der Verbindung.
        </p>{" "}
        <p>
          Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
          sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den
          Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten zählen
          insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten, Voicemails
          hochgeladene Fotos und Videos, Dateien, Whiteboards und andere
          Informationen, die während der Nutzung des Dienstes geteilt werden.
        </p>{" "}
        <p>
          Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
          Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere Möglichkeiten
          richten sich maßgeblich nach der Unternehmenspolitik des jeweiligen
          Anbieters. Weitere Hinweise zur Datenverarbeitung durch die Konferenztools
          entnehmen Sie den Datenschutzerklärungen der jeweils eingesetzten Tools, die
          wir unter diesem Text aufgeführt haben.
        </p>{" "}
        <h4>Zweck und Rechtsgrundlagen</h4>{" "}
        <p>
          Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden
          Vertragspartnern zu kommunizieren oder bestimmte Leistungen gegenüber
          unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b DSGVO). Des Weiteren dient
          der Einsatz der Tools der allgemeinen Vereinfachung und Beschleunigung der
          Kommunikation mit uns bzw. unserem Unternehmen (berechtigtes Interesse im
          Sinne von Art. 6 Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt
          wurde, erfolgt der Einsatz der betreffenden Tools auf Grundlage dieser
          Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die Zukunft
          widerrufbar.
        </p>{" "}
        <h4>Speicherdauer</h4>{" "}
        <p>
          Die unmittelbar von uns über die Video- und Konferenz-Tools erfassten Daten
          werden von unseren Systemen gelöscht, sobald Sie uns zur Löschung
          auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für
          die Datenspeicherung entfällt. Gespeicherte Cookies verbleiben auf Ihrem
          Endgerät, bis Sie sie löschen. Zwingende gesetzliche Aufbewahrungsfristen
          bleiben unberührt.
        </p>{" "}
        <p>
          Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
          Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir keinen
          Einfluss. Für Einzelheiten dazu informieren Sie sich bitte direkt bei den
          Betreibern der Konferenz-Tools.
        </p>{" "}
        <h4>Eingesetzte Konferenz-Tools</h4>{" "}
        <p>Wir setzen folgende Konferenz-Tools ein:</p>
        <h3>Zoom</h3>{" "}
        <p>
          Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom Communications Inc.,
          San Jose, 55 Almaden Boulevard, 6th Floor, San Jose, CA 95113, USA. Details
          zur Datenverarbeitung entnehmen Sie der Datenschutzerklärung von Zoom:{" "}
          <a
            href="https://zoom.us/de-de/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://zoom.us/de-de/privacy.html
          </a>
          .
        </p>{" "}
        <p>
          Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der
          EU-Kommission gestützt. Details finden Sie hier:{" "}
          <a
            href="https://zoom.us/de-de/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://zoom.us/de-de/privacy.html
          </a>
          .
        </p>
        <h2>11. Eigene Dienste</h2>
        <h3>Umgang mit Bewerberdaten</h3>{" "}
        <p>
          Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z.&nbsp;B. per
          E-Mail, postalisch oder via Online-Bewerberformular). Im Folgenden
          informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im Rahmen des
          Bewerbungsprozesses erhobenen personenbezogenen Daten. Wir versichern, dass
          die Erhebung, Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit
          geltendem Datenschutzrecht und allen weiteren gesetzlichen Bestimmungen
          erfolgt und Ihre Daten streng vertraulich behandelt werden.
        </p>{" "}
        <h4>Umfang und Zweck der Datenerhebung </h4>{" "}
        <p>
          Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit
          verbundenen personenbezogenen Daten (z.&nbsp;B. Kontakt- und
          Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
          Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die Begründung
          eines Beschäftigungsverhältnisses erforderlich ist. Rechtsgrundlage hierfür
          ist § 26 BDSG nach deutschem Recht (Anbahnung eines
          Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO (allgemeine
          Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt haben – Art. 6
          Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. Ihre
          personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich
          an Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt
          sind.
        </p>{" "}
        <p>
          Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten
          Daten auf Grundlage von § 26 BDSG und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke
          der Durchführung des Beschäftigungsverhältnisses in unseren
          Datenverarbeitungssystemen gespeichert.
        </p>{" "}
        <h4>Aufbewahrungsdauer der Daten</h4>{" "}
        <p>
          Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot
          ablehnen oder Ihre Bewerbung zurückziehen, behalten wir uns das Recht vor,
          die von Ihnen übermittelten Daten auf Grundlage unserer berechtigten
          Interessen (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung
          des Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei
          uns aufzubewahren. Anschließend werden die Daten gelöscht und die physischen
          Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere
          Nachweiszwecken im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass
          die Daten nach Ablauf der 6-Monatsfrist erforderlich sein werden (z.&nbsp;B.
          aufgrund eines drohenden oder anhängigen Rechtsstreits), findet eine
          Löschung erst statt, wenn der Zweck für die weitergehende Aufbewahrung
          entfällt.
        </p>{" "}
        <p>
          Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
          entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben oder
          wenn gesetzliche Aufbewahrungspflichten der Löschung entgegenstehen.
        </p>
        <h4>Aufnahme in den Bewerber-Pool</h4>{" "}
        <p>
          Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die Möglichkeit,
          Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der Aufnahme werden alle
          Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool übernommen, um
          Sie im Falle von passenden Vakanzen zu kontaktieren.
        </p>{" "}
        <p>
          Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf Grundlage
          Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die Abgabe
          der Einwilligung ist freiwillig und steht in keinem Bezug zum laufenden
          Bewerbungsverfahren.{" "}
          <strong>
            Der Betroffene kann seine Einwilligung jederzeit widerrufen.
          </strong>{" "}
          In diesem Falle werden die Daten aus dem Bewerber-Pool unwiderruflich
          gelöscht, sofern keine gesetzlichen Aufbewahrungsgründe vorliegen.
        </p>{" "}
        <p>
          Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach Erteilung
          der Einwilligung unwiderruflich gelöscht.
        </p>
      </>
    </TextPage>
  )
}

export default DatenschutzPage
