import React, { useState } from "react";
import { Helmet } from "react-helmet";
import AppIcon from "../../components/defaults/boxes/AppIcon";
import TextPage from "../../components/pages/TextPage";
import "./SupportPage.scss";
function SupportPage() {

  const [Category, setCategory] = useState(null);

  return (
    <TextPage title="Support">
      <div className="SupportPage ">
        <Helmet>
          <title>dashdesign; Support; ⛑</title>
          <meta name="description" content="Sie benötigen Hilfe? Hier finden Sie häufig gestellte Fragen, zu allen dashdesign Produkten: Grünberg-App, Mücke-App, IUS, Ihre Website oder App, ..." />
        </Helmet>
        <div className="container">
          <div className="text-center mt-5">
            <h1>Willkommen beim Support</h1>
            <h2 className="big mt-3 mb-5">
              {Category === null ? "Wobei benötigen Sie Hilfe?" :
                <span>
                  <span className="pointer" onClick={() => setCategory(null)}><i className="fad  fa-chevron-circle-left"></i> Auswahl ändern</span>
                </span>
              }
            </h2>
          </div>

          {Category === null ?

            <div className="d-flex flex-row flex-wrap align-items-stretch">
              <div className="col-6 col-md-3 p-0 align-items-stretch" onClick={() => setCategory(1)}>
                <div className="item">
                  <div className="appIcons">
                    <AppIcon size={72} appName="Grünberg" />
                    <AppIcon size={72} appName="Mücke" />
                    <AppIcon size={72} appName="Reiskirchen" />
                  </div>
                  <span className="title">Stadt App</span>
                </div>
              </div>
              <div className="col-6 col-md-3 p-0" onClick={() => setCategory(2)}>
                <div className="item">
                  <AppIcon appName="IUS" size={128} />
                  <span className="title">Insulaner Unner Sück</span>
                </div>
              </div>
              <div className="col-6 col-md-3 p-0" onClick={() => setCategory(2)}>
                <div className="item">
                  <i className="AppIcon fad  fa-globe-europe"></i>
                  <span className="title">Website & Hosting</span>
                </div>
              </div>
              <div className="col-6 col-md-3 p-0" onClick={() => setCategory(2)}>
                <div className="item">
                  <i className="AppIcon fad  fa-list-ul"></i>
                  <span className="title">Anderes Problem</span>
                </div>
              </div>
            </div> : null
          }


          <div className="infobox">

            <div className="row align-items-center">
              <div>
                <img className="qr" src="../media/image/company/qr.png"></img>
                <small className="text-center">Mit Handy-Kamera scannen</small>
              </div>
              <div className="p-5">
                <h2>dashdesign; Support</h2>
                <p>
                  <a href="tel:4915901427689">📞 06401 / 22 90 437</a><br />
                  <a href="mailto:info@dashdesign.eu">📧 info@dashdesign.eu</a>
                </p>
              </div>
            </div>

          </div>


          <br />


        </div>
      </div >
    </TextPage>
  );
}

export default SupportPage;
