import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import TextPage from "../../components/pages/TextPage";
import "./ContactPage.css";
function ContactPage() {
  const [Text, setText] = useState("");

  useEffect(() => {
    document.title = "Kontakt | dashdesign;";
  });

  // function handleTextEdit(e) {
  //   let newText = e.target.value;

  //   if (newText === "S" || newText === "H" || newText === "h") {
  //     newText = "";
  //   }
  //   if (
  //     newText === "Hallo Dashdesign,\nehr" ||
  //     newText === "Hallo Dashdesign,\nallo" ||
  //     newText === "Hallo Dashdesign,\n"
  //   ) {
  //     newText = "Hallo Dashdesign,\n";
  //   }
  //   if (Text === "") {
  //     setText("Hallo Dashdesign,\n" + newText);
  //   } else {
  //     setText(newText);
  //   }
  // }


  return (
    <TextPage title="Kontakt">
      <div className="ContactPage">

        <Helmet>
          <title>Kontakt | dashdesign.eu </title>
          <meta name="description" content="Wir freuen uns, Sie und Ihr Projekt kennenzulernen. Vereinbaren Sie gerne einen Termin oder kontaktieren Sie uns gerne auf dem klassischen Weg!" />
        </Helmet>

        <p>Sie haben Fragen an uns, möchten mehr über unsere Angebote erfahren oder uns einfach nur Ihre Meinung sagen?<br />
          Kontaktieren Sie uns, wir freuen uns über Feedback sind gerne für Sie da.</p>

        <div className="row mt-5">
          {/* <div className="col-8">
            <Formular {...formularProps} />
          </div> */}

          <div className="col-12 col-md-4 subBox">
            <h2>Hessen App GmbH</h2>

            <hr></hr>
            <h3>Post</h3>
            <p>Am alten Turm 1<br />35305 Grünberg</p>

            <hr></hr>
            <h3>Social Media</h3>
            <div className="icons">
              {socialMediaList.map((i, k) =>
                <span key={k} title={i.platform}>{i.icon}</span>
              )}
            </div>

            <hr></hr>
            <a href="tel:4964012290437">
              <h3>Telefon</h3>
              <p>06401 / 22 90 437</p>
            </a>

            <hr></hr>
            <a href="mailto:info@dashdesign.eu">
              <h3>E Mail</h3>
              <p>info@dashdesign.eu</p>
            </a>

            <hr></hr>
            <div style={{ opacity: 0.75 }}>
              <small>Hessen App GmbH<br />Poststr. 1.<br />35305 Grünberg<br /><Link target="_blank" to="/rechtliches/impressum/dashdesign-website/">Zum Impressum</Link></small>
            </div>
          </div>

          <div className="col-12 col-md-8">

            {/* <iframe src="https://tawk.to/chat/60687319067c2605c0bef87d/1f2c0370t" /> */}


          </div>
        </div>


        <br />
        {/* <div className="row">
        <input
        placeholder="Ihr Name*"
        required
        autocomplete="cc-name"
        className="col-xs-10 col-md-6"
        ></input>
        </div>
        <div className="row">
        <input
        placeholder="Firma"
        autocomplete="company"
        className="col-xs-10 col-md-6"
        ></input>
        </div>
        <div className="row">
        <input
        placeholder="Telefonnummer"
        autocomplete="tel"
        className="col-xs-10 col-md-6"
        ></input>
        <input
        placeholder="E-Mail"
        autocomplete="email"
        className="col-xs-10 col-md-6"
        ></input>
        </div>
        <div className="row">
        <input placeholder="Betreff*" className="col-xs-12 col-md-12"></input>
        </div>
        <div className="row">
        <textarea
        className="col-xs-12 col-md-12"
        rows="5"
        value={Text}
        onChange={(e) => handleTextEdit(e)}
        placeholder="Ihre Nachricht"
        ></textarea>
        </div>
      <br></br> */}
        {/* <p>
        <b>Möchten Sie uns lieber kontaktieren?</b>
      </p> */}

      </div>
    </TextPage>
  );
}

export default ContactPage;

const socialMediaList = [
  {
    icon: <i className="fab fa-instagram"></i>,
    platform: "Instagram",
    url: "https://www.instagram.com/dashdesign.eu/",
  },
  {
    icon: <i className="fab fa-facebook"></i>,
    platform: "Facebook",
    url: "https://fb.me/dashdesign.eu",
  },
  // {
  //   icon: <i className="fab fa-whatsapp"></i>,
  //   platform: "WhatsApp",
  //   url: "https://api.whatsapp.com/send?phone=4964018069919&text=Hallo%20Dashdesign%2C",
  // },
  {
    icon: <i className="fab fa-twitter"></i>,
    platform: "Twitter",
    url: "https://twitter.com/dashdesign_eu",
  },
  {
    icon: <i className="fab fa-xing"></i>,
    platform: "Twitter",
    url: "https://www.xing.com/companies/dashdesign",
  },
]

  // < i className="fab fa-facebook" ></i >
  //             <i className="fab fa-whatsapp"></i>
  //             <i className="fab fa-xing"></i>
  //             <i className="fab fa-linkedin"></i>