import React, { useEffect, useState } from 'react'
import "./UmweltschutzPage.css"

import Helmet from "react-helmet"
import { Link } from 'react-router-dom'
import HostingUmweltImg from "../../assets/image/more/umweltschutz/hosting-umwelt-hetzner-partner.jpg"
import KleinesDorfInMittelhessen from "../../assets/image/more/umweltschutz/KleinesDorfInMittelhessen.jpg"
import PlanzeInQUeckbornImg from "../../assets/image/more/umweltschutz/pflanze-in-queckborn.jpg"
import Wow1Img from "../../assets/image/more/umweltschutz/wowImg1.jpg"
import Wow2Img from "../../assets/image/more/umweltschutz/wowImg2.jpg"

function UmweltschutzPage() {
    const [Regenwald, setRegenwald] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setRegenwald(Math.round(Regenwald + 21.1))
        }, 100);
        return () => clearInterval(interval);
    }, [Regenwald]);

    return (
        <div className="UmweltschutzPage cityTheme grünberg" >
            <Helmet>
                <title>Umweltschutz bei dashdesign; 🌱 </title>
                <meta name="keywords" content="Grünberg, StartUp, Investment, Umwelt, FairTrade, Regenwald, Live-Anzeige, Hosting ohne Emissionen, Baum pflanzen, "></meta>
                <meta name="description" content="Unsere Server: 100% Ökostrom. 💨 Für jede Website oder App: Einen Baum 🌳
Umweltschutzprojekte: Unterstützen Wir  durch innovative Software ✅" />
            </Helmet>

            <div className="box" style={{ backgroundImage: "url(" + KleinesDorfInMittelhessen + ")" }}>
                <div className="inner">
                    <div className="container">
                        <h1>Umweltschutz bei dashdesign; <i className="fad  fa-leaf"></i></h1>
                        <h2>Wir geben alles, um die Veränderung zu sein, die wir uns selbst wünschen!</h2>
                    </div>
                </div>
            </div>


            <div className="box " style={{ backgroundImage: "url(" + HostingUmweltImg + ")" }}>
                <div className="inner-rev">
                    <div className="container ">
                        <div className="">
                            <h1>

                                <br />Hosting<br /> ohne Emissionen.</h1>
                            <h2>Alle unsere Server laufen mit 100% Ökostrom. <a target="_blank" href="https://www.hetzner.com/de/unternehmen/umweltschutz/"><i className="fad  fa-info-circle"></i></a></h2>
                            <div className="tags">
                                <Tag>Energie aus Wasserkraft</Tag>
                                <Tag>Effiziente Hardware</Tag>
                                <Tag>Gutes Gewissen</Tag>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="box" style={{ backgroundImage: "url(" + Wow2Img + ")" }}>
                <div className="inner-rev">
                    <div className="container">
                        <h1>Unser Planet ist zu besonders um ihn zu zerstören!</h1>

                        <h2>Seit dem diese Unterseite geöffnet wurde, <br />wurden ca. {Regenwald} Quadratmeter Regenwald zerstört.<br /><small>Quelle: umweltschutz.de</small></h2>
                    </div>
                </div>
            </div>


            <div className="box" style={{ backgroundImage: "url(" + PlanzeInQUeckbornImg + ")" }}>
                <div className="inner-rev">
                    <div className="container">
                        <h1>Für jeden unserer Aufträge pflanzen wir einen Baum. <i className="fad  fa-trees"></i></h1>
                        <h2>Wenn jeder einen kleinen Teil dazu beträgt, <br />können wir gemeinsam eine große Veränderung bewirken!</h2>
                    </div>
                </div>
            </div>




            <div className="box" style={{ backgroundImage: "url(" + Wow1Img + ")" }}>
                <div className="inner-rev">
                    <div className="container">
                        <h1>Feedback, Fragen oder Ideen?</h1>
                        <h2>Wir beantworten Ihre Fragen sehr gerne und freuen uns über Feedback.<br />Noch mehr freut uns aber, dass wir durch unsere Arbeit etwas Gutes für die Umwelt tun können.
                        </h2>
                        <div className="tags">
                            <Link to="/kontakt/"><Tag>Kontakt aufnehmen <i className="fa-solid fa-leaf"></i></Tag></Link>
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default UmweltschutzPage



function Tag(props) {
    return (
        <div className="tag">
            <span>{props.children}</span>
        </div>
    )
}