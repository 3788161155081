import React from "react";
import { Link } from "react-router-dom";
import "./FirstRow.scss";

export const FirstRow = ({ onToggle, isMenuOpen }) => {
  return (
    <div className="FirstRow">
      <div className="container-lg">
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">

          <div className="d-flex flex-row ">
            <div>
              <Link to="/" className="logo dashdesign">
                dashdesign
                <span className="bold">;</span>
              </Link>
            </div>
            <div className="menu-toggler d-md-none">
              <i
                onClick={() => onToggle()}
                className={
                  isMenuOpen
                    ? "fa-solid fa-chevron-up"
                    : "fa-solid fa-bars"
                }
              ></i>
            </div>
          </div>

          <div className="d-none d-md-block  Icons mt-5 mt-md-0">
            <div className="contactBox justify-content-between justify-content-md-left pl-4 pl-md-0">

              {/* Tel
              <a href="tel:4966349690990">
                <i className="fa-solid fa-phone mr-1"></i>
                <span className="d-none d-md-block">06401 / 22 90 437</span>
              </a> */}

              {/* E.Mail */}
              <a href="mailto:info@dashdesign.eu?body=Sehr%20geehrte%20Damen%20und%20Herren%2C">
                <i className="fa-solid fa-envelope mr-1"></i>
                <span className="d-none d-md-block">info@dashdesign.eu</span>
              </a>

              {/* Termin */}
              <Link to="/termin">
                <i className="fa-solid fa-calendar"></i>
                <span className="d-none d-md-block">Termin vereinbaren</span>
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};