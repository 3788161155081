import React from "react";
import TextPage from "../../../components/pages/TextPage";
import LandingPage from "../../home/boxes/LandingPage";

function PlatformPage() {
  return (
    <TextPage
      className="PlatformPage"
      title="Plattform-Entwicklung"
      subTitle="Innovative Plattform-Lösungen für Wachstum und Effizienz"
      noContainer
      img="https://blog.dashdesign.eu/wp-content/uploads/2023/01/smartmockups_kmuhfcad-scaled.jpg"
    >
      <LandingPage {...data} />
    </TextPage >
  );
}
export default PlatformPage;

const data = {
  fancyImage: "https://blog.dashdesign.eu/wp-content/uploads/2022/01/Dashdesign-asp-büro_IB-reinigung-website.png",
  ddFeatures: [
    {
      title: "Alles aus einer Hand",
      // text: <>Verwalten Sie Ihre Öffnungszeiten, Speisekarten, Angebote & Co. selber an einer zentralen Stelle. Alternativ pflegen wir Ihre Website auch gerne für Sie.</>,
      icon: <i className="fad  fa-phone-laptop"></i>,
    },
    {
      title: "Schicke Apps setzen sich durch",
      // text: <>Ihre neue Präsenz wechselt Unterseiten <b>ohne Ladezeiten</b></>,
      icon: <i className="fad  fa-rabbit-fast"></i>,

    },
    {
      title: "Ab dem 1. Tag Erfolge",
      // text: <>Mit starken regionalen <b>Partnern</b> aus den Bereichen Print und Hardware bieten wir Ihnen ein optimales Gesamtpaket für Ihre Projekte.</>,
      icon: <i class="fa-duotone fa-rocket-launch"></i>,
    },
  ],
  features: [
    {
      title: "App, Websites, Cloud und KI",
      icon: <i className="fad  fa-phone-laptop"></i>,
    },
    {
      title: "Umfassender Support",
      icon: <i class="fa-duotone fa-user-headset"></i>,
    },
    {
      title: "Fokus auf die Zukunft",
      icon: <i class="fa-duotone fa-robot"></i>,
    },
    {
      title: "Kompatibel mit Ihren Systemen",
      icon: <i className="fad  fa-sparkles"></i>,
    },
    {
      title: "Über 10 Jahre Erfahrung",
      icon: <i class="fa-duotone fa-brain-circuit"></i>,
    },
    {
      title: "Maßgeschneiderte Software",
      icon: <i className="fad  fa-code"></i>,
    },
    {
      title: "Festpreis oder nach Stunde",
      icon: <i class="fad fa-euro-sign"></i>,
    },
    {
      title: "Unabhängig von Drittanbietern",
      icon: <i className="fad  fa-people-carry"></i>,
    },
    {
      title: "Design, das sich durchsetzen kann",
      icon: <i class="fa-duotone fa-pen-paintbrush"></i>,
    },

    {
      title: "Kreative Ideen inklusive",
      icon: <i class="fa-duotone fa-lightbulb"></i>,
    },
  ]
}