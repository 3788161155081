import React from "react"
import StrapiImage from "../../../../components/api/strapi/StrapiImage/StrapiImage"
import { ProjectListItem } from "../../../../components/more/projects/ProjectListItem/ProjectListItem"
import "./ReferenceBox.scss"

function ReferenceBox({ projects, projectsImage }) {

    let list = projects.data


    return (
        <div>
            <div className="ReferenceBox">
                {/* <Fader /> */}
                <div className="container inner">

                    <div className="pb-5">
                        <h2>Referenzen</h2>
                        <span className="subTitle">Top Projekte die wir primär entwickeln</span>
                        <hr />
                    </div>


                    <div className="row">
                        {list.map((i, k) => <ProjectListItem {...i} key={k} />)}
                        <div className="col-12 col-md-6 imgBox" >
                            <StrapiImage image={projectsImage} />
                        </div>
                    </div>

                </div>
            </div>
            <Fader />
        </div>
    )
}
export default ReferenceBox


function Fader() {
    return (
        <svg className="Fader" xmlns="http://www.w3.org/2000/svg" width="2781.754" height="124.416" viewBox="0 0 2781.754 124.416">
            <path id="_2" data-name="2" d="M-870.1-107.065c-529.669-71.772-1358.033-64.946-1358.033-64.946l761.727-11.553s164.8-13.629,298.05,0c331.919,33.949,1557.674,0,1557.674,0v76.5s-70.294-64.211-235.248,8.972S-780.886-94.977-870.1-107.065Z" transform="translate(2388.698 189.623)" fill="var(--red)" opacity="1" />
            <path id="_1" data-name="1" d="M-12.8,0H2768.954s-217.173,3.383-254.873,18.422c-40.911,16.321-121.58,44.836-365.435,55.645-251.329,11.139-574.175-39.724-856.137-25.4-241,12.239-475.224,22.47-721.689,25.4C37.345,80.418-12.8,18.422-12.8,18.422Z" transform="translate(12.8)" fill="var(--bgSub)" />
        </svg>

    )
}