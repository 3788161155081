export function printConsoleInfoLog() {
    console.log(
        "\n\n\n\n\n\n\n%cInfos von dashdesign;\n",
        "color: #0477d1; font-family:arial; font-weight: 600; font-size: 28px"
    );
    console.log(
        "%cBitte füge keinen Code in diese Konsole ein, den du nicht verstehst!\n",
        "color: #fc5c65; font-family:arial;font-weight: 600; font-size: 28px"
    );
    console.log(
        "%cHacker können über diese Konsole an persönliche Daten gelangen. Diesen Angriff nennt man 'XSS-Angriff'.\n\nWenn du einfach ein Programmierer bist, wünschen wir dir viel Spaß in unserem Code! ☕️😊",
        "font-family:arial;font-weight: 300; font-size: 22px"
    );
}