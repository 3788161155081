import React from 'react'
import { Helmet } from 'react-helmet'
import TextPage from '../../components/pages/TextPage'

function ImpressumPage() {
    return (
        <TextPage title="Impressum">
            <Helmet>
                <title>Impressum</title>
                <meta name="description" content="Das Impressum von dashdesign.eu" />
            </Helmet>

            <>
                <h2>Angaben gemäß § 5 TMG</h2>
                <p>
                    Hessen App GmbH<br />
                    Am alten Turm 1<br />
                    35305 Grünberg
                </p>
                <p>
                    Inhaber: Konrad Mayer<br />
                    Geschäftsführer: Konrad Mayer</p>
                <p>
                    Handelsregister: HRB 11407
                    <br />
                    Registergericht: Amtsgericht Gießen
                </p>
                <h2>Kontakt</h2>
                <p>
                    Telefon: 06401 / 22 90 426<br />
                    E-Mail: info@dashdesign.eu
                </p>
                <h2>Umsatzsteuer-ID</h2>
                <p>
                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                    <br />
                    Folgt Zeitnah.  <br />
                    Steuernummer: 020 235 62745
                </p>
                <h2>Angaben zur Berufs­haftpflicht­versicherung</h2>
                <p>
                    <strong>Name und Sitz des Versicherers:</strong>
                    <br />
                    Allianz Versicherungs-Aktiengesellschaft
                    <br />
                    Königinstr. 28
                    <br />
                    80802 München
                </p>
                <p>
                    <strong>Geltungsraum der Versicherung:</strong>
                    <br />
                    Deutschland
                </p>
                <h2>Redaktionell verantwortlich</h2>
                <p>Johannes C. Ehler</p>
                <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
                <p>
                    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
                    einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>
            </>

        </TextPage>


    )
}

export default ImpressumPage
