import React from "react"
import { STRAPI_ADDRESS } from "../../../../const/api/endpoints"
import "./VideoHeader.scss"

function VideoHeader({ headerVideo, slogan, services }) {
    return (
        <div className="VideoHeader">
            <div className="overlay"></div>

            <video playsInline autoPlay="autoplay" muted="muted" loop="loop">
                <source src={STRAPI_ADDRESS + headerVideo?.data?.attributes?.url} type="video/mp4" />
            </video>

            <div className="cont d-flex h-100 ">
                <div className="container">

                    <h1 className="mb-5">{slogan || "Wir entwickeln besondere Projekte."}</h1>

                </div>
            </div>
        </div>
    )
}
export default VideoHeader

