import React from "react"
import { STRAPI_ADDRESS } from "../../../../const/api/endpoints"
import "./StrapiFile.scss"

function StrapiFile({ id, attributes }) {

    let { name, size, ext, url, caption } = attributes || {}

    return (
        <a href={STRAPI_ADDRESS + url} target="_blank" >
            <div className="StrapiFile col-12 col-md-6">
                <div className="">
                    <i className="fa-solid fa-file" />
                </div>

                <div>
                    <span className="caption">{caption}</span>
                    {/* <span className="fileName">{name}</span> */}
                    <span className="size">ca. {Math.round(size)}mb</span>
                </div>
            </div>
        </a>
    )
}
export default StrapiFile