import React from "react";
import Helmet from "react-helmet";
import PageLoader from "../../../components/defaults/loader/PageLoader";
import { ServerStatusMessage } from "../../../components/more/serverStatus/ServerStatusMessage/ServerStatusMessage";
import TextPage from "../../../components/pages/TextPage";
import { useServerStatus } from "../../../services/more/useServerStatus";
import "./ServerStatus.scss";

function ServerStatus() {
  const { Data, Loading, Error } = useServerStatus()


  if (Loading) return <PageLoader />
  if (Error) return <p>Status kann nicht geladen werden.</p>

  if (Data) {

    let { attributes } = Data
    let { description, createdAt, updatedAt, publishedAt, messages } = attributes

    messages = [...messages]

    let activeMsgs = []
    messages.forEach((msg, k) => {
      if (msg.isActive) {
        activeMsgs.push(msg)
        messages.splice(k, 1)
        console.log(msg)
      }
    });

    return (
      <TextPage title="Server-Status" className="ServerStatus">
        <Helmet>
          <title>Aktueller Server-Status: ✅ Keine Störungen | dashdesign; </title>
          <meta name="description" content="Alle Störungsmeldungen aus unseren emissionsfreien Rechenzentren. 🌱 Server-Status für dashdesign;-Websites, " />
        </Helmet>
        <div >


          <p>{description}</p>

          <div className="mb-5">
            <h2>Aktuelle Meldungen:</h2>
            <div>
              {activeMsgs.length == 0 ? <div>Aktuell keine Ausfälle.</div> : activeMsgs.map((i, k) => <ServerStatusMessage {...i} key={k} />)}
            </div>
          </div>


          <div className="mb-5">
            <h2>Vergangene Meldungen:</h2>
            <div>
              {messages.map((i, k) => <ServerStatusMessage {...i} key={k} />)}
            </div>
          </div>


        </div>
      </TextPage>
    );
  }
}

export default ServerStatus;

