import React from "react";
import { Helmet } from "react-helmet";
import TextPage from "../../../components/pages/TextPage";
import LandingPage from "../../home/boxes/LandingPage";
import "./WebsitesPage.scss";

function WebsitesPage() {

  return (
    <TextPage
      className="WebsitesPage"
      title="Web-Entwicklung"
      subTitle="Vertrauen Sie Ihren Webauftritt dashdesign; an!"
      noContainer
      img="https://blog.dashdesign.eu/wp-content/uploads/2023/01/smartmockups_lbl49b63-scaled.jpg"
    >
      < Helmet >
        <title>Website und Onlineshops | Festpreise, Mobile-First</title>
        <meta name="description" content="Websites clever gedacht!" />
      </Helmet >


      <LandingPage {...data} />

    </TextPage >
  );
}
export default WebsitesPage;

const data = {
  fancyImage: "https://blog.dashdesign.eu/wp-content/uploads/2022/01/Dashdesign-asp-büro_IB-reinigung-website.png",
  ddFeatures: [
    {
      title: "Kompatibel mit unseren Stadt-Apps",
      text: <>Verwalten Sie Ihre Öffnungszeiten, Speisekarten, Angebote & Co. selber an einer zentralen Stelle. Alternativ pflegen wir Ihre Website auch gerne für Sie.</>,
      icon: <i className="fad  fa-phone-laptop"></i>,
    },
    {
      title: "Keine Ladezeiten beim Seitenwechsel",
      text: <>Ihre neue Präsenz wechselt Unterseiten <b>ohne Ladezeiten</b></>,
      icon: <i className="fad  fa-rabbit-fast"></i>,

    },
    {
      title: "All in One & Starke Partner",
      text: <>Mit starken regionalen <b>Partnern</b> aus den Bereichen Print und Hardware bieten wir Ihnen ein optimales Gesamtpaket für Ihre Projekte.</>,
      icon: <i className="fad  fa-drafting-compass"></i>,
    },
  ],
  features: [
    {
      title: "Für alle Gerätetypen",
      icon: <i className="fad  fa-phone-laptop"></i>,
    },
    {
      title: "Corporate Design",
      icon: <i className="fad  fa-drafting-compass"></i>,
    },
    {
      title: "Dezente Animationen",
      icon: <i className="fad  fa-sparkles"></i>,
    },
    {
      title: "Für Google optimiert",
      icon: <i className="fad  fa-chart-line"></i>,
    },
    {
      title: "Maßgeschneiderte Software",
      icon: <i className="fad  fa-code"></i>,
    },
    {
      title: "Inhalte 24/7 anpassen (lassen)",
      icon: <i className="fad  fa-wand-magic"></i>,
    },
    {
      title: "Festpreis oder nach Stunde",
      icon: <i className="fad  fa-stopwatch"></i>,
    },

    {
      title: "Helles & dunkles Design",
      icon: <i className="fad  fa-adjust"></i>,
    },

    {
      title: "Unabhängig von Drittanbietern",
      icon: <i className="fad  fa-people-carry"></i>,
    },
  ]
}