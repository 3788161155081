import axios from "axios"
import { useEffect, useState } from "react"
import { HOMEPAGE_ENDPOINT } from "../../const/api/endpoints"
import { STRAPI_API_KEY } from "../../const/api/keys"

export function useHomepage() {
    const [Data, setData] = useState({})
    const [Error, setError] = useState(false)
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        (
            async function () {
                let endpoint = HOMEPAGE_ENDPOINT
                setError(false)
                setLoading(true)

                let config = {
                    method: 'get',
                    url: endpoint,
                    headers: {
                        'Authorization': `Bearer ${STRAPI_API_KEY}`
                    }
                };

                console.log(endpoint)

                axios(config)
                    .then(res => { console.log(res.data?.data?.attributes); setData(res.data?.data?.attributes || {}) })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}