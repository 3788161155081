import React from "react"
import { useApi } from "../../../../services/useApi"
import PageLoader from "../../../defaults/loader/PageLoader"

function ItemRenderer({ endpoint, render, loader = <PageLoader />, error = <p>Fehler beim laden.</p> }) {
    const { Data, Loading, Error } = useApi(endpoint)

    if (Error) return error
    if (Loading) return loader
    if (Data) {
        let value = Data

        if (Array.isArray(Data) && Data.length > 0) {
            value = Data[0]?.attributes
        }

        return render(value)
    }
}
export default ItemRenderer