import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import MückeLogo from "../../assets/image/company/partner/gemeinde_muecke_logo.png"
import GrünbergLogo from "../../assets/image/company/partner/grünberg.png"
import GwgLogo from "../../assets/image/company/partner/gwg.svg"
import PartnerImg from "../../assets/image/more/partner/dashdesign-mitarbeiter-und-website-kunde.jpg"
import PdfGB from "../../assets/pdf/Empfehlungschreiben.pdf"
import TextPage from '../../components/pages/TextPage'
import "./PartnerPage.css"



const partnerList = [
    {
        title: "Stadt Grünberg",
        subtitle: "& Tourismusbüro Grünberg",
        imgSrc: GrünbergLogo,
        content: <iframe className="w-100" width="560" height="315" src="https://www.youtube-nocookie.com/embed/_jbc7Ey1Vuo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
        text: "Zusammen mit der Stadt Grünberg entwickeln wir die offizielle Grünberg-App.",
        buttons: [
            { text: "Empfelungsschreiben", url: PdfGB, },
            { text: "Grünberg-App", url: "https://diestadt.app/grünberg", }
        ]
    },
    {
        title: "Gemeinde Mücke",
        subtitle: "& Tourismusbüro Grünberg",
        imgSrc: MückeLogo,
        content: <div className="p-0 p-md-5"><img src={MückeLogo} className="col-10 p-5 m-0 m-md-5" /></div>,
        text: "Zusammen mit der Gemeinde Mücke entwickeln wir die offizielle Gemeinde Mücke-App.",
        buttons: [
            { text: "Mücke-App", url: "https://diestadt.app/mücke", }
        ]
    },
    // {
    //     title: "Allianz",
    //     subtitle: "Dos Santos",
    //     imgSrc: null,
    //     text: "Für die lokale Allianz-Vertretung aus Grünberg und Gießen sind wir als Berater tätig. Wir sind selbst bei der Allianz abgesichert, falls einmal etwas Unvorhergesehenes passieren sollte.",
    //     content: <div className="p-0 p-md-5"><img src={AllianzLogo} className="col-10 p-5 m-0 m-md-5" /></div>,
    //     buttons: [
    //         { text: "Instagram", url: "https://www.instagram.com/agentur.santos/", },
    //     ]
    // },
    {
        title: "GWG Grünberg",
        text: "Zusammen mit der Grünberger Werbegemeinschaft entwickeln wir die offizielle Grünberg-App.",
        imgSrc: GwgLogo,
        content: <iframe className="w-100" width="560" height="315" src="https://www.youtube-nocookie.com/embed/CltrdQFmnjk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>,
        buttons: [
            { text: "GWG-Seite", url: "https://gwg-gruenberg.de/mitglieder/dashdesign/", },
            { text: "Grünberg App", uri: "/city/grünberg", }
        ]
    },
    {
        title: "Gill IT-Service",
        subtitle: "Unser Hardware-Partner",
        // imgSrc: "https://blog.dashdesign.eu/wp-content/uploads/2021/06/gill_it_grünberg_logo_light.png",
        text: "",
        content: <div className="p-0 p-md-5"><img style={{ maxHeight: 300, }} src="https://blog.dashdesign.eu/wp-content/uploads/2021/06/gill-it_grünberg_logo_light.png" /></div>,
        buttons: [
            { text: "Website", url: "https://gill-its.de/", },
        ]
    },
    // {
    //     title: "rapper.today",
    //     subtitle: "Nachrichtenquelle",
    //     text: "Für rapper.today haben wir die Webseite, sowie eine leicht bedienbare Konsole gebaut. Weiterhin war das Design unsere Idee.",
    //     content:
    //         <img className="w-100" src={RapperTodayLogo} />,
    //     buttons: [
    //         { text: "Web", url: "https://rapper.today/", },
    //         { text: "YouTube", url: "https://www.youtube.com/channel/UC6nIAqYkbLzjQJ72skU1lHQ", },
    //         { text: "Google News", url: "https://news.google.com/publications/CAAqBwgKMM_Kngsw5tS2Aw?hl=de&gl=DE&ceid=DE%3Ade", },
    //     ]
    // },
    // {
    //     title: "Weltladen",
    //     subtitle: "Grünberg",
    //     content: <div className="svgBox"><WeltladenLogo /></div>,
    //     text: "Der Weltladen in Grünberg ist eine Klasse Sache. Wenn wir mal Zeit finden, machen wir gerne Werbung für den Weltladen.",
    //     buttons: [
    //         { text: "Instagram", url: "https://www.instagram.com/weltladen.gruenberg/", },
    //         { text: "Grünberg App Eintrag", url: "https://gruenberg.app/kategorie/firmen/78", },
    //         { text: "Web", url: "http://weltladen-gruenberg.de/", },
    //     ]

    // },
]

function PartnerPage() {
    return (
        <div className="PartnerPage">
            <Helmet>
                <title>Unsere Partner - Zusammen stark mit dashdesign;</title>
                <meta name="description" content="Unseren Partnern: Stadt Grünberg, Gemeinde Mücke, Allianz Gießen, Gill-IT, GWG, Punctum, rapper.today, Grünberger Weltladen und noch mehr." />
            </Helmet>

            <TextPage title="Partner" img={PartnerImg}>
                <div className="mb-5">
                    <p>Eine gute Zusammenarbeit ist uns sehr wichtig. Hier finden Sie eine Übersicht unserer engsten Partner. <br />Einen Überblick über unsere Projekte finden Sie hier: <Link to="/referenzen/">Referenzen öffnen</Link></p>
                </div>
                {partnerList.map((i, k) =>
                    <PartnerItem {...i} key={k} />
                )}
            </TextPage>
        </div>
    )
}
export default PartnerPage

function PartnerItem(p) {
    return (
        <div className="boxi PartnerItem mb-5">
            <div className="row p-0">
                <div className="col-12 col-md-6 p-0 boxL">
                    {p.content}
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="p-4">
                        <h2>{p.title}</h2>
                        {/* <img className="icon" src={p.imgSrc} /> */}
                        <span className="subtitle">{p.subtitle}</span>
                        <p>{p.text}</p>
                        <div>
                            {p.buttons?.map((button, key) =>
                                <>{
                                    button.uri ?
                                        <Link target="_blank" to={button.uri} key={key}>
                                            <button >
                                                {button.text}
                                                <i className="fa-solid fa-external-link-square ml-3"></i>
                                            </button>
                                        </Link>
                                        :
                                        <a target="_blank" href={button.url} key={key}>
                                            <button >
                                                {button.text}
                                                <i className="fa-solid fa-external-link-square ml-3"></i>
                                            </button>
                                        </a>
                                }</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}