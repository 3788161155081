import React from "react"
import { STRAPI_ADDRESS } from "../../../../const/api/endpoints"
import "./StrapiImage.scss"

function StrapiImage({ image }) {
    let img = image?.data?.attributes || {}
    let { name, alternativeText, caption, formats, url } = img

    if (!url) return <></>

    let src = formats?.medium?.url || url
    src = STRAPI_ADDRESS + src

    return (
        <div className="StrapiImage">
            <img className="w-100" src={src} alt={alternativeText} />
        </div>
    )
}
export default StrapiImage