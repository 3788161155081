import React, { useEffect, useState } from "react"
import "./BigKeyValue.scss"
import CountUp from 'react-countup';

function BigKeyValue(i) {
    return (
        <div className={"BigKeyValue " + i.className}>
            <div className="mb-5">
                <span className="factValue"><small>{i.pre}</small>{i.value ? <CountUpText value={i.value} /> : null}{i.after}</span>
                <span className="factKey">{i.text}</span>
                <p>{i.small}</p>
            </div>
        </div>
    )
}
export default BigKeyValue

function CountUpText({ value }) {
    let duration = 3
    let startAt = value * 0.3
    if (value < 10) startAt = 0
    return <CountUp start={startAt} end={value} duration={duration} />
}