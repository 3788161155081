import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import AllianzLogo from "../../assets/image/company/partner/allianz.svg"
import GrünbergLogo from "../../assets/image/company/partner/grünberg.png"
import GwgLogo from "../../assets/image/company/partner/gwg.svg"
import PunctumLogo from "../../assets/image/company/partner/punctum-gruenberg.svg"
import WeltladenLogo from "../../assets/image/company/partner/weltladen-gruenberg.svg"
import GrimmichImg from "../../assets/image/grimmich/grünberg-webdesigner.JPG"
import Reference from "../../components/defaults/boxes/Reference"
import FaqBlock from "../../components/defaults/text/FaqBlock"
import Quote from "../../components/defaults/text/Quote"
import "./AboutPage.css"

function AboutPage() {
    return (
        <div className="AboutPage">
            <Helmet>
                <title>Über dashdesign;</title>
                <meta name="description" content="Wir sind ein junges Start-up, spezialisiert auf Websites, Apps und Onlinemarketing.\nUnsere Vision: Menschen begeistern. (Standort in Grünberg, Mittelhessen)" />
            </Helmet>
            <div className="fancyBox">
                <br />
                <h1 className="text-center">Über <span className="blue">dash</span><span className="red">design</span><span className="blue">;</span></h1><br />
                <div className="container">
                    <h2 className="text-center">Unsere Vision</h2><br />
                    <div className=" boxi p-5">
                        <p><b>Hi, wir sind dashdesign; 😊</b></p>
                        <p>
                            Unsere Vision: <b>Wir möchten Projekte umsetzen, die Menschen begeistern, beeindrucken und ihre Leben vereinfachen.</b> <br />Unsere Mission: Die Erreichung dieses Zieles durch das Anbieten von innovativen Softwarelösungen mit einem besonderen Augenmerk auf Design.
                            Das Design ist der erste Eindruck, wenn es um Produkte geht und der erste Eindruck zählt. Hier liegt unser Schwerpunkt, denn diese Impression entscheidet, ob Leute einer Software eine Chance geben. Anwendungen stehen und fallen somit mit ihrem Aussehen, was besonders auch für Werbetreibende entscheidend ist.
                            Dennoch, eine schöne Verpackung ist natürlich nicht alles, die Funktionalität entscheidet, ob eine Anwendung weiterhin benutzt wird. Deswegen bieten wir auch inhaltlich starke Produkte, die einen wirklichen Mehrwert bieten.
                            <br /><br />Nicht überzeugt? Fragen Sie uns und überzeugen Sie sich selbst, wir lieben es, Erwartungen zu übertreffen!<br /><br />

                            <b>Konrad Mayer<br />
                                <small>CEO Hessen App GmbH</small>
                            </b>
                        </p>

                    </div>
                </div>

                <div className="locationBox d-block">
                    <div className="row align-items-center pb-4">
                        <div className="col-lg-7 col-12 p-0">
                            <div className="effect-image-wrapper">
                                <img alt="Ausblick über die Stadt Grünberg" className="effect-image" src={GrimmichImg} />
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 p-5 subBox">
                            <h2>Made in Grünberg</h2>
                            <br />
                            <p>
                                Unsere Programmierer arbeiten primär aus Grünberg, einer malerischen Kleinstadt im mittelhessischen Landkreis Gießen.</p>

                            <p><b>dashdesign; Partner:</b></p>

                            <div className="partnerLogos">
                                <a href="https://www.gruenberg.de/" target="_blank">
                                    <img alt="Logo Stadt Grünberg" title="Stadt Grünberg" src={GrünbergLogo} />
                                </a>
                                <a href="https://gwg-gruenberg.de/" target="_blank">
                                    <img alt="GWG-Grünberg Logo" title="Grünberger Werbegemeinschaft e.V." src={GwgLogo} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="container pb-5">
                        <p>
                            <small><b>Ausblick in Richtung Grünberger Innenstadt</b> | © dashdesign & Stadt Grünberg</small>
                        </p>
                    </div>
                </div>

            </div>



            <div className="fancyBox">
                <div className="container d-block mt-5 mb-5 pb-5">

                    <h2 className="mt-5">Unsere Geschichte</h2>
                    <br />

                    <h3>2012 - 2016</h3>
                    <p>Die Geschichte von dashdesign; beginnt 2013 in dem Baukastenspiel Minecraft. Zusammen mit Freunden hat Konrad Mayer hier ein Servernetzwerk entwickelt, auf dem zeitweise bis zu 50 Spieler gleichzeitig aktiv waren.
                    </p>


                    <br />
                    <h3>2016 - 2019</h3>
                    <p>Das damalige Team entschied sich nun 2016 dazu den schnellsten URL-Shortener namens ili.pw zu programmieren. Dieser ist leider nicht mehr online, aber bot eine Chance viele Erfahrungen zu sammeln.</p>

                    <p>Nach ili.pw begannen wir mit einem gigantischen Projekt: einem sozialen Netzwerk. </p>
                    <br />

                    <h3>2019</h3>
                    <p>In diesem Jahr fingen wir mit der Programmierung von modernen Kundenwebseiten an. Wir legen großen Wert auf ein intuitives Design und erfüllen Ihnen jeden Wunsch – von Google Optimierung bis zum Webshop.

                        2020 trafen sich die Inselbewohner der 6 deutschen nordfriesischen Inseln. Da Teile des Teams  dort wohnen entwickelten wir eine App für das jährliche Event.
                    </p><br />

                    <p> </p>
                    <br />


                    <h3>2020</h3>
                    <p>Das Jahr begann mit einem großen Schritt für uns: wir lassen uns als Hessen App GmbH ins Handelsregister eintragen.</p>
                    <p>Social-Media-Marketing z. B. über Instagram wird immer wichtiger, das wissen auch wir. Von uns betreute Accounts konnten bereits nach 3 Monaten über 100.000 Impressionen erzielen.</p>

                    <p>Zum Beginn der Coronapandemie konnten wir einen unserer größten Erfolge erzielen: eine Corona-App. Trotz der Vielzahl an Features wurde diese mittlerweile leider durch die offizielle App ersetzt. Zur Unterbrechung von Kontaktketten wird sie allerdings bis heute weiter benutzt.</p>

                    <p>
                        Wir fanden ein neues Ziel: Die Digitalisierung Grünbergs - und wie ginge das besser, als mit einer offiziellen App?
                        Unsere Anwendung listet heute über 50 Betriebe und Lokale. Sie bietet den Bewohnern, Unternehmen und Touristen viele Vorteile, wie etwa tägliche Angebote, Rabatte und Informationen rund um Corona.
                        Über 10 % der Einwohner verwenden mittlerweile unsere App, ein tolles Gefühl!
                    </p>

                    <Quote text="Insbesondere in den Zeiten der Pandemie wurde wieder einmal deutlich, wie wichtig digitale Kommunikation geworden ist."
                        author="Frank Ide (ehem. Bürgermeister Grünberg)" />


                    <br />
                    <h3>2021</h3>
                    <p>Wir arbeiten an einer offiziellen App für die Gemeinde Mücke.</p>


                    {/* <p> Gemeinde Mücke App, Zahlreiche UUpdates, Konsole Update???, KI-Marpl kein Erfolg, Team wächst, Vorbereitung für mittelhessen?. Austausch auf messen</p>

                    <br />
                    <h3>2022</h3>
                    {/* <p>Wir möchten unseren Social-Meida Bereich erweitern.
                    In naher Zukunft möchten wir Werbe-Videos für Kunden produzieren.</p> */}
                    {/* Zum Jahresanfang größeres Team, 

                    Fette app geplant, was mit dp zu tun */}


                    Vielen Dank für Ihr Interesse an dashdesign;! Bald gibt es hier Neuigkeiten.


                </div>
                <br />   <br />
            </div>

            <div className="box-partner ">
                <div className="container">

                    <h2 className="text-center mb-5">Partner</h2>

                    <Link to="/partner/">
                        <div className="partner-logos-wrapper">
                            <div className="row">
                                <div className="col">
                                    <img className="partner-logo w-100" src={GrünbergLogo} />
                                </div>
                                <div className="col">
                                    <img className="partner-logo w-100" src={PunctumLogo} />
                                </div>
                                <div className="col">
                                    <img className="partner-logo w-100" src={AllianzLogo} />
                                </div>
                                <div className="col">
                                    <img className="partner-logo w-100" src={WeltladenLogo} />
                                </div>
                                <div className="col">
                                    <img className="partner-logo w-100" src={GwgLogo} />
                                </div>

                            </div>
                        </div>

                        <p className="text-center up"><b><a href="#partner">Eine Übersicht der öffentlichen Partner findest du hier.</a></b></p>
                    </Link>
                </div>
            </div>


            <div className="fancyBox p-5">
                <div className="container p-5">
                    <h2 className="text-center p-5">Referenz-Projekte</h2>
                    <div>
                        {references.map((i, k) =>
                            <Reference {...i} key={k} />
                        )}
                    </div>
                </div>
            </div>

            <div className="p-5">
                <div className="container p-5">
                    <FaqBlock subTitle=" " title={<h2>FAQ</h2>} questions={questions}></FaqBlock>
                </div>
            </div>

        </div >
    )
}

export default AboutPage

const questions = [
    {
        title: "Woher kommt der Name dashdesign;?",
        content: <p>Dash kommt aus dem englischen und bedeutet Strich oder Priese.
            Dieser Teil kommt von dem sozialen Netzwerk dashpoll und steht dafür das wir niemals den roten fad en verlieren.
            Außerdem steht dash auch noch für die feine Priese, die unsere Produkte von der Konkurrenz abhebt.
            <br />
            Design spielt bei unseren Projekten immer eine große Rolle.
            Das Design ist häufig der erste Eindruck. Außerdem entscheidet das Design darüber, wie gerne eine Software benutzt wird oder ob eine Werbung wirkt.
        </p>
    },

    {
        title: "Was bedeutet das Semikolon?",
        content: <p>
            Das Semikolon wird bei einigen Programmiersprachen als Trennzeichen verwendet. Viele Programmierer sind der Meinung, dass Semikolon steigert die Codequalität. Das Semikolon im Dashdesign-Logo steht gleichzeitig als Erkennungszeichen für Programmierer und für qualitativen Code.
        </p>
    },

    {
        title: "Was ist die Vision von dashdesign;?",
        content: <p>
            Unsere Vision: Wir möchten Projekte umsetzen, die Menschen begeistern oder beeindrucken. <br />Um dieses Ziel zu erreichen, fokussieren wir uns nicht nur auf innovative Projekte, sondern auch auf das Design.
            <br />Das Design ist häufig der erste Eindruck. Außerdem entscheidet das Design darüber, wie gerne eine Software benutzt wird oder ob eine Werbung wirkt.
            <br />Design ist aber nicht alles, unser Ziel ist es deswegen ein optimales Gesamtergebnis abzuliefern.
            Das bedeutet für uns auch 200% zu geben, um z.B. einen unmöglichen Termin einzuhalten.
            <br /><br />Wir lieben es Erwartungen zu übertreffen.
        </p>
    },



    {
        title: "Welche Dienstleistungen bietet dashdesign; an?",
        content: <p>
            Wir bieten folgende Dienstleistungen an: Webprogrammierung, App-Entwicklung, Server-Hosting,
            Social Media-Marketing, Design, Druck und Stadtmarketing.
            Auf <Link to="/kontakt/">Anfrage</Link> führen wir eventuell weitere Dienstleistungen durch.
        </p>
    }
]

const references = [

    {
        url: "/grimmich/",
        title: "Grünberg App",
        subtitle: "Die offizielle App der Stadt Grünberg",
        appIconSrc: "https://play-lh.googleusercontent.com/GrZEXc4zaYH5bK6MCLwWZl3ErTgPEDhmLH49HB7ImmktGShFE61nEqGLQDgceyH1Rzaf=s360-rw",
        headerText: "Zusammen mit der Stadt Grünberg und der Grünberg Werbegemeinschaft bieten wir eine tolle App für alle Einwohner und Touristen. Am Handy & Desktop verfügbar",
    },
    {
        url: "/project/ius",
        title: "Insulaner unner sück",
        subtitle: "Eventkalender, aktuelle Informationen & Karte mit Hotels",
        appIconSrc: "https://play-lh.googleusercontent.com/FE48yUjvKSy5V9mfc8btCnKBIeEAuSMqSb4Fiou7MyZ37AfbVDbsRYj2Bi-M6WGDjmY=s1080-rw",
        headerText: "Seit über 20 Jahren treffen sich Bewohner der sieben ostfriesischen Inseln abwechselnd auf einer Insel - dashdesign; hat 2020 zusammen mit der Insel Norderney eine App für dieses Event entwickelt.",
    },

]
