import React from 'react';
import { Helmet } from "react-helmet";
import GoBackButton from '../../components/defaults/buttons/GobackButton';

function Page404() {
    return (
        <div className="container Page">
            <Helmet>
                <title>Seite nicht gefunden! (Fehler 404) | dashdesign;</title>
                <meta name="description" content="Diese Unterseite existiert nicht(mehr). Hier gibt es nur Websites, Apps, Designs und Marketing." />
            </Helmet>
            <h1 className="big">404 - Seite nicht gefunden!</h1>
            <GoBackButton>
                <button>Zurück / Zur Startseite</button>
            </GoBackButton>
            <br />
            <br />
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/dashdesign.eu/"
            >
                <i className="fab fa-instagram"></i> dashdesign; auf Instagram
            </a>
        </div>
    )
}

export default Page404
