import React, { useState } from "react";
import { FirstRow } from "./FirstRow";
import "./NavbarTop.scss";
import { SecondRow } from "./SecondRow";

function NavbarTop() {

  const [ShowMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <nav className="top navbar navbar-expand-lg navbar-light">
        <div className="w-100">
          <FirstRow
            onToggle={() => setShowMobileMenu(!ShowMobileMenu)}
            isMenuOpen={ShowMobileMenu}
          />
          <SecondRow closeMobileMenu={() => setShowMobileMenu(false)} showMobileMenu={ShowMobileMenu} />
        </div>
      </nav>

      <div className="navTopPush" />
    </>
  );
}
export default NavbarTop;