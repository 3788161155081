import React from "react";
import { Link } from "react-router-dom";
import "./Reference.css";

/**
 * @param props {{reference: Reference}}
 * @return {JSX.Element}
 * @constructor
 */
function Reference(props) {

    let payload = props

    return (
        <div className="Reference col-12">
            <div className="d-flex  align-items-center">
                <img alt={payload.iconAlt || ""} className="appIcon" src={payload.iconSrc} />

                <div className="ml-5 d-block">
                    <span className="title">{payload.title}</span>
                    <span className="subtitle">{payload.subtitle}</span>
                </div>
            </div>

            <div className="ml-3 mr-3 mt-5">
                <span className="headerText">{payload.headerText}</span>
                {
                    payload?.uri?.substr(0, 4) === "http"
                        ? <a target="_blank" className="more" href={payload.uri}>{payload.readMoreText ?? 'Website öffnen'}</a>
                        : <Link className="more" to={payload.uri || "/"}>{payload.readMoreText ?? 'Mehr erfahren'}</Link>
                }
            </div>
        </div>
    );
}

export default Reference;