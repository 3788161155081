import React from 'react'
import { Link } from 'react-router-dom'
import "./LandingPage.scss"

function LandingPage({ ddFeatures, features, fancyImage }) {
    return (
        <div className="LandingPage">
            <div className="container">



                <div className="box features ">
                    <h3>Unschlagbarer Service:</h3>
                    <div className="row">
                        {features.map((i, k) =>
                            <span
                                key={k}
                                className="col-12 col-md-6  mb-4">
                                <div className="item">
                                    <span className="icon">{i.icon}</span>
                                    {i.title}
                                </div>
                            </span>
                        )}
                        <a href="#kontakt" className='mt-5'>
                            <button>Kontakt aufnehmen</button>
                        </a>
                    </div>
                </div>

                <div className="box ExclusiveBox row align-items-center">
                    <div className="features col-12 col-lg-6">
                        <h3>Exklusiv bei dashdesign;</h3>
                        <div className="row">
                            {ddFeatures.map((i, k) =>
                                <div key={k} className=" d-flex flex-column flex-lg-row  mb-5">
                                    <span className="icon">{i.icon}</span>
                                    <div>
                                        <span
                                            className="item col-12 p-0">
                                            {i.title}
                                        </span>
                                        <p>{i.text}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="box col-12 col-lg-6 mb-5 mb-lg-0">
                        <img className='w-100' src={fancyImage} />
                    </div>

                </div>
            </div>


            <div className="container" id="kontakt">
                <h3><big>Haben wir Ihr Interesse geweckt?</big></h3>
                <div className="d-flex flex-md-row flex-column ">
                    <Link to="/kontakt/" >
                        <button>Kontaktieren Sie uns!</button>
                    </Link>
                </div>

            </div>
        </div>
    )
}
export default LandingPage



//todo: Referenzen
//todo: Preis 
//todo: Ablauf 
//todo: Suffix
