import React from "react"
import { Link } from "react-router-dom"
import StrapiImage from "../../../api/strapi/StrapiImage/StrapiImage"
import "./ProjectListItem.scss"
export function ProjectListItem({ attributes }) {

    let { projectTitle, shortDescription, color, logo, slug } = attributes

    return <div className="col-12 col-md-6">
        <div className="ProjectListItem">
            <div>
                <div className="titleBox">
                    {logo.data ?
                        <StrapiImage image={logo} />
                        :
                        <h3>{projectTitle}</h3>
                    }
                </div>

                <p>{shortDescription}</p>
                <Link to={`/projekt/${slug}`}>
                    <button style={{ backgroundColor: color }}>Mehr zum Projekt</button>
                </Link>
            </div>
            <hr />
        </div>
    </div>
}