import React from "react";
import PageLoader from "../../components/defaults/loader/PageLoader";
import { useHomepage } from "../../services/more/useHomepage";
import PageServerError from "../more/PageServerError/PageServerError";
import AboutBox from "./boxes/AboutBox/AboutBox";
import ReferenceBox from "./boxes/ReferencesBox/ReferenceBox";
import VideoHeader from "./boxes/VideoHeader/VideoHeader";
import WelcomeBox from "./boxes/WelcomeBox";
import "./HomePage.scss";

function HomePage() {

  const { Data, Loading, Error } = useHomepage()

  if (Loading) return <PageLoader />
  if (Error) return <PageServerError />
  if (Data) {

    let { slogan, projects, vision, projectsImage, headerVideo, services } = Data

    return (
      <div className="HomePage">
        <VideoHeader headerVideo={headerVideo} slogan={slogan} services={services} />

        <ReferenceBox projects={projects} projectsImage={projectsImage} />
        <WelcomeBox services={services?.data} />
        <AboutBox {...vision} />
        {/* <WebsiteBox />
      <ApplicationsBox /> */}
      </div>
    );
  }
}
export default HomePage;