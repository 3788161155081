import React from 'react'
import JohannesImg from "../../assets/image/team/johannes_constantin_ehrler.png"
import KonradMayerImg from "../../assets/image/team/konradmayer.png"
import TextPage from "../../components/pages/TextPage"
import "./TeamPage.css"



function TeamPage() {
    return (
        <TextPage className="TeamPage" title="Ansprechpartner im Überblick">
            {members.map((i, k) =>
                <Member {...i} key={k} />
            )}
        </TextPage>
    )
}

export default TeamPage

const members = [
    {
        name: "Johannes Constantin Ehrler",
        title: "Kommunikationsmanager & Ansprechpartner Presse",
        image: JohannesImg,
        text1: <div>
            <p>Johannes
                Schülerzeitung & Abi, Vereinszeitung</p>
        </div>,
        text2: <p>Bevor Konrad 2020 dashdesign; gründete, sammelte er schon zahlreiche Erfahrungen darin, eigene Projekte zu leiten.</p>,
        contact: <p><a href="mailto:johannes.ehrler@dashdesign.eu"><i className="fad  fa-envelope-open-text"></i> johannes.ehrler@dashdesign.eu</a></p>
    },

    // {
    //     name: "Alexander Rudolf von Glahn",
    //     title: "Vertriebsdirektor (CCO)",
    //     image: AlexRvgImg,
    //     text1: <div>
    //         <p>Alexander Rudolf von Glahn leitet die Kundenkommunikation vom Dashdesign.</p>
    //         <p>Seine Hauptaufgabe besteht nicht nur darin die Kundenintressen zu vertreten,
    //             er stellt die Kunden in den Mittelpunkt des Unternehmens.</p>
    //         <p>Als guter Zuhörer & kreativer Kopf fördert er die Kundenzufriedenheit enorm.
    //             Er stellt außerdem sicher das dashdesign; Leistungsversprechen hält und die Ziele der Kunden stehts einhält.</p>
    //     </div>,
    //     text2: <div>
    //         <p>Alex gehört zu den ersten Mitarbeitern von dashdesign. Als ehmaliger Schulfreund von Konrad Mayer hat er besondern Einfluss auf die Geschichte von dashdesign; genommen.</p>

    //         <p>Bereits vor seine Ausbildung zum Softwareentwickler, veröffetnlichte er erste Projekt wie Socialstack. </p>
    //     </div>,
    //     contact: <p><a href="mailto:vertrieb@dashdesign.eu"><i className="fad  fa-envelope-open-text"></i> vertrieb@dashdesign.eu</a></p>

    // },

    // {
    //     name: "Lenny Bakkalian",
    //     title: "Leiter Sicherheit (CSO)",
    //     image: LennyImg,
    //     text1: <div>
    //         <p>Alexander Rudolf von Glahn leitet die Kundenkommunikation vom Dashdesign.</p>
    //         <p>Seine Hauptaufgabe besteht nicht nur darin die Kundenintressen zu vertreten,
    //             er stellt die Kunden in den Mittelpunkt des Unternehmens.</p>
    //         <p>Als guter Zuhörer & kreativer Kopf fördert er die Kundenzufriedenheit enorm.
    //             Er stellt außerdem sicher das dashdesign; Leistungsversprechen hält und die Ziele der Kunden stehts einhält.</p>
    //     </div>,
    //     text2: <div>
    //         <p>Alex gehört zu den ersten Mitarbeitern von dashdesign. Als ehmaliger Schulfreund von Konrad Mayer hat er besondern Einfluss auf die Geschichte von dashdesign; genommen.</p>

    //         <p>Bereits vor seine Ausbildung zum Softwareentwickler, veröffetnlichte er erste Projekt wie Social Stack. </p>
    //     </div>,
    // },

    {
        name: "Konrad Mayer",
        title: "Geschäftsführer, Hessen App GmbH",
        image: KonradMayerImg,
        text1: <div>
            <p>Konrad Mayer ist der Gründer von dashdesign; und Mitgleid der Geschäftsführung. </p>
            <p>Als das Gesicht hinter dashdesign; konzentiert sich Konrad die strategischen
                Ziele festzulegen.
                Sein Ziel ist es, das Unternehmen voranzubringen und den richtigen Weg für die Zukunft zu suchen.
            </p>
        </div>,
        text2: <p>Bevor Konrad 2020 dashdesign; gründete, sammelte er schon zahlreiche Erfahrungen darin, eigene Projekte zu leiten.</p>,

        contact: <p><a href="mailto:info@dashdesign.eu"><i className="fad  fa-envelope-open-text"></i> info@dashdesign.eu</a></p>
    },
]

function Member(props) {
    return (
        <div className="Member">
            <div className="titleBox">
                <div>
                    <h2>{props.name}</h2>
                    <span className="position">{props.title}</span>
                </div>
                <div>
                    <img src={props.image}></img>
                </div>
            </div>
            <div className="textBox">
                <div className="row">
                    <div className="col-12 col-md-6">
                        {props.text1}
                    </div>
                    <div className="col-12 col-md-6">
                        {props.text2}
                        {props.contact}
                    </div>
                </div>
            </div>
        </div>
    )
}