import React from "react";
import { Link } from "react-router-dom";
import "./FooterContainer.scss";

function FooterContainer(props) {
  let year = new Date().getFullYear() || "2022";
  let copyrightText = "© " + year + " Hessen App GmbH - Alle Rechte vorbehalten.";

  return (
    <footer>
      <div className="container">
        <div className="row mb-5">


          <div className="col-xs-12 col-md-6 col-lg-3">
            <h3>dashdesign;</h3>
            <div className="items">

              <Link to="/about/">Über dashdesign;</Link>

              {/* <Link to="/team/">Unser Team</Link> */}
              <Link to="/partner/">Partner</Link>
              <Link to="/umweltschutz/">Umweltschutz</Link>
              <Link to="/karriere/">Karriere</Link>
            </div>

            {/* <div className="items">{PartnerLinks()}</div>{" "} */}
          </div>


          <div className="col-xs-12 col-md-6 col-lg-3 ">
            <h3>Social Media</h3>
            <div className="items">

              <a
                target="_blank"
                href="https://www.linkedin.com/showcase/dashdesign"
                className="item"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin"></i>
                dashdesign.eu
              </a>

              <a
                target="_blank"
                href="https://www.instagram.com/dashdesign.eu"
                className="item"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
                dashdesign.eu
              </a>



              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCmWob1nEpEkIypPvs5rF5BA"
                className="item"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
                dashdesign;
              </a>


            </div>
          </div>
          {/* 
          <div className="col-xs-12 col-md-4">
            <h3>Dashdesign</h3>
            <div className="items">
              <Link to="/impressum/">Über Uns</Link>
              <Link to="/impressum/">Team</Link>
              <Link to="/impressum/">Refferenzen</Link>
              <Link to="/impressum/">Jobs</Link>
            </div>{" "}
          </div> */}

          <div className="col-xs-12 col-md-6 col-lg-3">
            <h3>Hilfe</h3>
            <div className="items">
              <Link to="/kontakt/">Kontakt</Link>
              <a href="https://blog.dashdesign.eu" target="_blank">Blog</a>
              <Link to="/support/">Support</Link>

            </div>
          </div>


          <div className="col-xs-12 col-md-6 col-lg-3">
            <h3>Mehr</h3>
            <div className="items">
              <a href="https://mittelhessen.app/" target="_blank">Die Mittelhessen-App</a>
              <Link to="/server-status/">Serverstatus</Link>
              <Link to="/projekte/">Projekte</Link>
              {/* <Link to="/investor-relations/">Investoren</Link> */}
              <a href="https://blog.dashdesign.eu/presse" target="_blank">Presse</a>
            </div>
          </div>
        </div>

        <div className="copyrightArea">
          <div className="d-flex flex-row justify-content-between">
            <div className="copyright">{copyrightText}</div>

            <div className="lowItems">
              <Link to="/datenschutz/">Datenschutzrichtlinie</Link>
              <Link to="/impressum/">Impressum</Link>
              <a href="#uc-corner-modal-show">Cookie-Einstellungen</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
const partner = [
  { url: "https://punctum-werbeagentur.eu/", title: "Punctum Werbeagentur" },
  { url: "https://gwg-gruenberg.de/", title: "Grünberger Werbegemeinschaft" },
  { url: "https://grimmich.de", title: "Stadt Grünberg" },
  { url: "https://weltladen-gruenberg.de/", title: "Weltladen Grünberg" },
  // { title: "dashpoll" },
  // { url: "https://12mayer.de", title: "12mayer" },
  // { url: "https://ib-reinigung.de/", title: "IB-Reinigung" },
  // { url: "https://immobilien-mm.com", title: "MM-Immobilien" },
];
function PartnerLinks() {
  let items = [];

  partner.forEach((item, key) =>
    items.push(
      <a key={key} href={item.url} target={item.url ? "_blank" : ""} rel="noopener noreferrer">
        {item.title}
      </a>
    )
  );

  return <div>{items}</div>;
}

export default FooterContainer;
