import React from "react"
import { getDateString } from "../../../../functions/date"
import "./ServerStatusMessage.scss"

export function ServerStatusMessage({ id, title, description, isActive, endTime, startTime, labels }) {
    labels = labels.data
    return (
        <div className="ServerStatusMessage">
            <div>
                {labels.map((i, k) => <Label {...i.attributes} key={k} />)}
            </div>

            <h3 className="title">{title}</h3>
            <p className="description"> {description}</p>

            <span>Start: {getDateString(startTime)}</span><br />
            <span>Ende: {getDateString(endTime) || "N/A"}</span>
        </div>
    )
}


function Label({ title, color, backgroundColor, icon }) {
    return <div style={{ backgroundColor: backgroundColor, color: color }} className="Label">
        <i className={`fa-solid fa-${icon}`} />
        <span>{title} </span>
        {/* {icon} */}
    </div>
}