import React from "react"
import StrapiFile from "../StrapiFile/StrapiFile"
import "./StrapiFileList.scss"

function StrapiFileList({ data = [] }) {

    console.log(data)

    return (
        <div className="StrapiFileList ">
            {
                data.map((i, k) => <StrapiFile key={k} {...i} />)
            }
        </div>
    )
}
export default StrapiFileList