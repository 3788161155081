import React from 'react'
import DieStadtAppImg from "../../../assets/image/apps/berlin.png"
import CoronaImg from "../../../assets/image/apps/corona.png"
import DashdesignImg from "../../../assets/image/apps/dashdesign.png"
import DashpollImg from "../../../assets/image/apps/dashpoll.png"
import GrünbergImg from "../../../assets/image/apps/Grünberg.png"
import IUSImg from "../../../assets/image/apps/ius.png"
import MückeImg from "../../../assets/image/apps/Mücke.png"
import ReiskirchenImg from "../../../assets/image/apps/Reiskirchen.png"
import "./AppIcon.scss"




function AppIcon(props) {
    let appSrc
    let appAlt

    let appIconsSrc = process.env.PUBLIC_URL + "/media/image/appIcons/"

    switch ((props.appName + "").toLowerCase()) {
        case ("mittelhessen"):
            appSrc = appIconsSrc + "mittelhessen-app.png"
            break;
        case ("grünberg"):
            appSrc = GrünbergImg
            break;
        case ("mücke"):
            appSrc = MückeImg
            break;
        case ("reiskirchen"):
            appSrc = ReiskirchenImg
            break;
        case ("dashpoll"):
            appSrc = DashpollImg
            break;
        case ("dashdesign"):
            appSrc = DashdesignImg
            break;
        case ("ius"):
            appSrc = IUSImg
            break;
        case ("corona"):
            appSrc = CoronaImg
            break;
        case ("city"):
            appSrc = DieStadtAppImg
            break;
        case ("berlin"):
            appSrc = DieStadtAppImg
            break;
        default:
            appSrc = GrünbergImg
            break;
    }

    return (
        <img className={"AppIcon " + (props.className ? props.className : null)}
            style={{
                maxWidth: props.size || 128
            }}
            alt={appAlt} src={appSrc} />
    )
}

export default AppIcon
