import React from "react";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import "./assets/styles/colors.scss";
import "./assets/styles/fonts.scss";
import "./assets/styles/style.scss";
import FooterContainer from "./components/navbar/footer/FooterContainer";
import NavbarTop from "./components/navbar/top/NavbarTop";
import { printConsoleInfoLog } from "./functions/console";
import NavigationProvider from "./provider/NavigationProvider";

function App() {
  printConsoleInfoLog()
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <div style={{ overflowX: "hidden" }}>
          <NavbarTop />
          <NavigationProvider />
          <FooterContainer />
        </div>
      </BrowserRouter>
    </div >
  );
}
export default App;