import { useEffect, useState } from "react"
import { STRAPI_API_ADDRESS } from "../const/api/endpoints"
import { STRAPI_API_KEY } from "../const/api/keys"
import { alenaxios } from "../functions/alenaxios"

export function useApi(endpoint) {
    const [Data, setData] = useState({})
    const [Error, setError] = useState(false)
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        (
            async function () {
                setError(false)
                setLoading(true)

                let config = {
                    method: 'get',
                    url: STRAPI_API_ADDRESS + endpoint,
                    headers: {
                        'Authorization': `Bearer ${STRAPI_API_KEY}`
                    }
                };

                alenaxios(config)
                    .then(res => {
                        setData(res.data?.data || {})
                    })
                    .catch(() => setError(true))
                    .finally(() => setLoading(false))
            }
        )()
    }, [])

    return { Data, Loading, Error }
}