import React, { useState } from "react";
import "./FaqBlock.css";

function FaqBlock(props) {
  return (
    <div className="FaqBlock">
      <div className="d-flex">
        <div className="inner col-12 col-sm-12 col-md-12">
          <p className="text-center">{props.subTitle || "Nochmal alles zusammengefasst"}</p>
          <h2 className="text-center">
            <b>{props.title || "Häufige Fragen"}</b>
          </h2>
          {props.questions?.map((p, k) => (
            <Question {...p} key={k} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Question(props) {
  const [Open, setOpen] = useState();
  return (
    <div className={Open ? "Question open" : "Question"}>
      <div
        className="d-flex justify-content-between inner"
        onClick={() => setOpen(!Open)}
      >
        <span className="question">{props.title}</span>
        <i className="far fa-plus-octagon"></i>
      </div>
      <div className="content">{props.content}</div>
    </div>
  );
}

export default FaqBlock;
