import React from "react"
import BigKeyValue from "."

function BigKeyValueList({ list = [], className, listClassName, itemClassName }) {
    return (
        // <div className={"BigKeyValueList " + className}>
        <div className={"d-flex flex-column flex-md-row " + listClassName}>
            {list.map((i, k) =>
                <BigKeyValue {...i} key={k} className={itemClassName} />
            )}
        </div>
        // </div>
    )
}
export default BigKeyValueList