import axios from "axios";

export function alenaxios(request) {
  let cacheKey = request.url;
  let cacheDurationInMinutes = request.cacheDurationInMinutes || 60
  let cachedResponse = localStorage.getItem(cacheKey);

  if (!request.method) request.method = "GET"

  if (cachedResponse) {
    let cacheTimestamp = localStorage.getItem(cacheKey + ':timestamp');
    let age = Date.now() - cacheTimestamp;
    let cacheDurationInMilliseconds = cacheDurationInMinutes * 60000;

    if (age < cacheDurationInMilliseconds) {
      // console.log("Use local axios cache " + cacheKey)
      return Promise.resolve({ data: JSON.parse(cachedResponse) });
    } else {
      // console.log("Use axios  " + cacheKey)
      localStorage.removeItem(cacheKey);
      localStorage.removeItem(cacheKey + ':timestamp');
    }
  }

  return axios(request)
    .then(response => {
      let responseToCache = JSON.stringify(response.data);
      localStorage.setItem(cacheKey, responseToCache);
      localStorage.setItem(cacheKey + ':timestamp', Date.now());
      return response;
    });
}


export function alenaxiosGet(url, ttl = 3600) {
  return new Promise((resolve, reject) => {
    let endpoint = url
    let storageName = endpoint;

    if (storageName) {


      let storeData = getStore(storageName)

      if (storeData) { // geht nicht!!! - ok keine var, doppelt abrufen :(


        resolve(storeData)
      }
      else {

        alenaxios(url)
          .then((res) => {
            store(storageName, ttl, res.data);
            resolve(res.data);
          })
          .catch((res) => reject(res));

      }
    } else {
    }
  });
}

const CurrentDate = Date.now() / 1000;

function getStore(storageName) {
  let storeData = localStorage.getItem(storageName)

  let { data, ttl, } = JSON.parse(storeData || "{}")

  if (ttl && data && ttl > CurrentDate) {

    return data
  }
  return false
}


async function store(storageName, ttl, data) {


  let storeString = JSON.stringify({
    ttl: CurrentDate + (ttl * 1000),
    data: data,
  });

  localStorage.setItem(storageName, storeString);
}
