import React from 'react';
import TextPage from '../../../components/pages/TextPage';

function PageServerError() {
    return (
        <TextPage noGoBack title="Serverfehler" className="container Page">
            <p>Bitte versuchen Sie es gleich erneut.</p>
        </TextPage>
    )
}

export default PageServerError
