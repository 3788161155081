import React from 'react'
import "./Quote.css"

function Quote(props) {
    return (
        <div className="Quote">

            <div className="main">
                <span className="char">„</span>

                {props.continuation ?
                    <span className="char">(...) </span>
                    : null}

                <p className="text">
                    {props.text}
                </p>


                {props.interruption ?
                    <span className="char"> (...)</span>
                    : null}


                <span className="char">“</span>
            </div>


            {props.author ?
                <span className="author">
                    - {props.author}
                </span>
                : null}
        </div>
    )
}

export default Quote
