import React from "react";
import Helmet from "react-helmet";
import { Parallax } from 'react-parallax';
import PageTitle from "../defaults/text/PageTitle";
import "./TextPage.scss";

function TextPage(props) {
  let { title, metaDescription, subTitle, metaTitle } = props
  return (
    <div className={props.className} id={props.id} >
      <div className="TextPage">

        < Helmet >
          <title>{metaTitle || title || "Unterseite"}</title>
          <meta name="description" content={metaDescription || subTitle || title || "Keine Beschreibung verfügbar."} />
        </Helmet >

        {props.img ?
          <Parallax className="Parallax"
            blur={{ min: -15, max: 2 }}
            bgImage={props.img} bgImageAlt={props.title + "-Titelbild"}
            strength={600}>
          </Parallax>
          : null}

        <div className="content">
          <div>
            <div className="container">
              <PageTitle noGoBack={props.noGoBack} children={props.title} subTitle={props.subTitle} />
            </div>
            <div className={props.noContainer ? "" : "container"}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TextPage;