import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import NavbarTopMenu from "./NavbarTopMenu";
import "./SecondRow.scss";

export const SecondRow = ({ showMobileMenu, closeMobileMenu }) => {
  const [Menu, setMenu] = useState(null);

  const menuItems = [
    // {
    //   title: "Unsere Apps",
    //   props: {
    //     to: "/referenzen"
    //   }
    // },
    {
      title: "App-Entwicklung",
      props: {
        to: "/entwicklung/app"
      }
    },
    {
      title: "Web-Entwicklung",
      props: {
        to: "/entwicklung/web"
      }
    },
    {
      title: "Plattform-Entwicklung",
      props: {
        to: "/entwicklung/platform"
      }
    },
    {
      title: "Unsere Projekte",
      props: {
        to: "/projekte"
      }
    },
    // {
    //   title: "Mittelhessen-App",
    //   props: {
    //     to: "/referenzen/mittelhessen-app/"
    //   }
    // },
    // {
    //   title: "S",
    //   props: {
    //     to: "/referenzen"
    //   }
    // },
  ];

  return (
    <div className={showMobileMenu ? "d-block" : "d-none d-md-block"}>
      <div className="SecondRow">
        <div className="container-lg">
          <div className="d-flex flex-md-row flex-column align-items-start align-items-md-center justify-content-between w-100">

            <div className="d-flex flex-row align-items-center">
              <div className="mr-5 inner">
                <div className="d-flex  flex-column flex-md-row">
                  {menuItems.map((i, k) =>
                    <div key={k} className="py-3 py-md-0">
                      <NavLink
                        {...i.props}
                        onClick={() => closeMobileMenu()}
                        className={({ isActive }) => isActive ? "menuItem isActive" : "menuItem"} >
                        {i.title}
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="py-3 py-md-0">
                <span onClick={() => { setMenu(true); closeMobileMenu() }} className="menuItem">Mehr dashdesign;</span>
              </div>
            </div>

          </div>
        </div>

        <NavbarTopMenu isActive={Menu} closeMenu={() => setMenu(false)} />
      </div>
    </div>
  );
};