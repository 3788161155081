import React from "react";
import A from "../../text/A";
import "./FeatureBox.scss";

function FeatureBox({ title, description, uri, }) {
    return (
        <div className=" FeatureBox">


            <div>
                {/* Die Mittelhessen App */}
                <h3>{title}</h3>

                {/* Desc */}
                <p>{description}</p>
            </div>

            {/*  Mehr dazu */}
            <A uri={"/entwicklung/" + uri}>
                <button style={{ minWidth: 128 }}>
                    Mehr erfahren <i className="pl-5 far fa-chevron-right"></i>
                </button>
            </A>
        </div>
    );
}
export default FeatureBox;