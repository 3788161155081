// export const STRAPI_ADDRESS = "http://localhost:1337"
export const STRAPI_ADDRESS = process.env.REACT_APP_STRAPI_URL || "https://api.dashdesign.eu"


export const STRAPI_API_ADDRESS = STRAPI_ADDRESS + "/api"

export const SERVER_STATUS_ENDPOINT = STRAPI_API_ADDRESS + "/page-server-status?populate[messages][populate][0]=labels"
export const HOMEPAGE_ENDPOINT = STRAPI_API_ADDRESS + "/page-homepage?populate[0]=services&populate[1]=vision.author&populate[2]=vision.author.image&populate[3]=projects&populate[4]=projectsImage&populate[5]=projects.logo&populate[6]=headerVideo"

export const PROJECT_ENDPOINT = STRAPI_API_ADDRESS + "/api/projects?populate=*"
export const PROJECT_LIST_ENDPOINT = "/projects?populate=*"